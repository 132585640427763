export default {
    price: 1000,
    light: {
        primary: '#D2D219',
        secondary: '#424242',
        accent: '#FFFF82',
    },
    dark: {
        primary: '#D2D219',
        secondary: '#424242',
        accent: '#FFFF82',
    }
}
