export default {
    price: 1000,
    light: {
        primary: '#72400D',
        secondary: '#424242',
        accent: '#D2802D',
    },
    dark: {
        primary: '#72400D',
        secondary: '#424242',
        accent: '#D2802D',
    }
}
