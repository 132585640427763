export default {
  miningDamage: '伤害',
  miningToughness: '韧性',
  miningOreGain: '矿石增益',
  miningRareEarthGain: '稀土增益',
  miningPickaxeCraftingPower: '制作力量',
  miningPickaxeCraftingSlots: '制作插槽',
  miningPickaxePremiumCraftingSlots: '高级制作插槽',
  miningPickaxeCraftingQuality: '制作纯度',
  miningOreQuality: '矿石品质',
  miningDepthDwellerSpeed: '深度居民速度',
  miningDepthDwellerMax: '尽可能高的居民深度',
  miningSmelterySpeed: '冶炼速度',
  miningSmelteryTemperature: '冶炼温度',
  miningResinMax: '最大树脂',
  currencyMiningHeliumIncrement: '氦增量',
  currencyMiningNeonIncrement: '氖增量',
  currencyMiningArgonIncrement: '氩增量',
  currencyMiningKryptonIncrement: '氪增量',
  currencyMiningXenonIncrement: '氙增量',
  currencyMiningRadonIncrement: '氡增量',
  miningEnhancementBarsIncrement: '金属锭增量',
  miningEnhancementFinalIncrement: '增强增量',
  villageWorker: '工人',
  villageTaxRate: '税率',
  queueSpeedVillageBuilding: '建造速度',
  villageOfferingPower: '供品力量',
  villageResourceGain: '所有资源增益',
  villageMaterialGain: '材料增益',
  villageMaterialCap: '材料容量',
  villageFoodGain: '食物增益',
  villageMentalGain: '精神资源增益',
  villageHappiness: '幸福',
  villagePolicyTaxes: '税收政策',
  villagePolicyImmigration: '移民政策',
  villagePolicyReligion: '宗教政策',
  villagePolicyScanning: '扫描政策',
  villageHousingCap: '住房最高等级',
  villageWorkstationCap: '工作站最高等级',
  villagePollution: '污染',
  villagePollutionTolerance: '污染耐受性',
  villagePower: '力量',
  villageLootGain: '战利品增益',
  villageLootQuality: '战利品品质',
  hordeAttack: '攻击',
  hordeHealth: '生命值',
  hordeRecovery: '恢复',
  hordeCritChance: '暴击率',
  hordeCritMult: '暴击伤害',
  hordeRevive: '复活',
  hordeMaxItems: '装备容量',
  hordeToxic: '中毒',
  hordeFirstStrike: '先发制人',
  hordeSpellblade: '法术之刃',
  hordeCutting: '切割',
  hordeDivisionShield: '分裂盾',
  hordeStunResist: '眩晕抵抗',
  hordeShieldbreak: '破盾',
  hordeEnemyActiveStart: '敌人开始冷却时间',
  hordePhysicConversion: '物理伤害转换',
  hordeMagicConversion: '魔法伤害转换',
  hordeBioConversion: '生物伤害转换',
  hordePhysicAttack: '造成的物理伤害',
  hordeMagicAttack: '造成的魔法伤害',
  hordeBioAttack: '造成的生物伤害',
  hordePhysicTaken: '受到的物理伤害',
  hordeMagicTaken: '受到的魔法伤害',
  hordeBioTaken: '受到的生物伤害',
  hordeLoot: '基本战利品',
  hordeItemChance: '装备几率',
  hordeBossRequirement: 'Boss要求',
  hordeRespawn: '重生时间',
  hordeHeirloomChance: '传家宝几率',
  hordeHeirloomAmount: '最大传家宝增益',
  hordeHeirloomEffect: '传家宝效果',
  hordeNostalgia: '怀旧之情',
  hordeCorruption: '腐败',
  hordeItemMasteryGain: '精通点数增益',
  hordeMinibossTime: '小Boss时间',
  hordeShardChance: '神秘碎片几率',
  powerHeirloomEffect: '力量效果',
  fortitudeHeirloomEffect: '韧效果',
  wealthHeirloomEffect: '财富效果',
  spiritHeirloomEffect: '精神效果',
  sharpsightHeirloomEffect: '锐视效果',
  holdingHeirloomEffect: '保持效果',
  reapingHeirloomEffect: '收割效果',
  remembranceHeirloomEffect: '纪念效果',
  expertiseHeirloomEffect: '专长效果',
  mysteryHeirloomEffect: '神秘效果',
  farmExperience: '作物经验',
  farmGoldChance: '黄金几率',
  farmTiles: '农场地块',
  farmGrow: '生长时间',
  farmOvergrow: '过度生长',
  farmRareDropChance: '稀有掉率',
  farmMystery: '神秘',
  farmDnaNext: '下一个声望 DNA',
  farmCropGain: '作物增益',
  farmAllGain: '全部收获增益',
  galleryInspirationBase: '灵感时间基数',
  galleryInspirationIncrement: '灵感时间增量',
  galleryInspirationStart: '灵感的开始',
  galleryColorGain: '所有颜色增益',
  galleryColorDrumChance: '所有鼓几率',
  galleryColorDrumCap: '所有鼓上限',
  galleryRedConversion: '红色转换',
  galleryRedDrumChance: '红色鼓几率',
  galleryOrangeConversion: '橙色转换',
  galleryOrangeDrumChance: '橙色鼓几率',
  galleryYellowConversion: '黄色转换',
  galleryYellowDrumChance: '黄色鼓几率',
  galleryGreenConversion: '绿色转换',
  galleryGreenDrumChance: '绿色鼓几率',
  galleryBlueConversion: '蓝色转换',
  galleryBlueDrumChance: '蓝色鼓几率',
  galleryPurpleConversion: '紫色转换',
  galleryPurpleDrumChance: '紫色鼓几率',
  "galleryDeep-orangeConversion": '橙红转换',
  "galleryDeep-orangeDrumChance": '橙红鼓几率',
  galleryAmberConversion: '金色转换',
  galleryAmberDrumChance: '金色鼓几率',
  "galleryLight-greenConversion": '浅绿色转换',
  "galleryLight-greenDrumChance": '浅绿色鼓几率',
  galleryTealConversion: '青色转换',
  galleryTealDrumChance: '青色鼓几率',
  "galleryLight-blueConversion": '浅蓝色转换',
  "galleryLight-blueDrumChance": '浅蓝色鼓几率',
  galleryPinkConversion: '粉色转换',
  galleryPinkDrumChance: '粉红鼓几率',
  miningCardCap: '矿山卡片容量',
  villageCardCap: '村庄卡片容量',
  hordeCardCap: '部落卡片容量',
  galleryCardCap: '画廊卡片容量',
  treasureSlots: '宝藏插槽',
  cryolabMaxFeatures: '最大冻结的功能',
  merchantOffers: '商户优惠',
  bankInvestmentSize: '投资规模',
  bankLoanSize: '贷款规模',
  bankCardPackChance: '银行卡包机会',
  cindersProductionFirefly: '光增益 (萤火虫)',
  cindersProductionGlowshroom: '光增益 (发光蘑菇)',
  cindersProductionGlowfish: '光增益 (发光鱼)',
  cindersProductionLantern: '光增益 (灯)',
  cindersProductionCampfire: '光增益 (营火)',
  cindersProductionCoral: '光增益 (珊瑚)',
  cindersProductionJellyfish: '光增益 (海蜇)',
  cindersProductionNightbloom: '光增益 (夜花)',
  cindersProductionNeonlight: '光增益 (霓虹灯)',
  cindersProductionSun: '光增益 (太阳)',
  cindersFirstProducerCost: '第一生产者成本',
  cindersNonFirstProducerCost: '非第一生产者成本',
  cindersUpgradeLightCost: '升级光成本',
  cindersUpgradeProducerRequirement: '升级生产者要求',
  cindersCandlePower: '蜡烛力量',
  bloomInventorySize: '库存大小',
  bloomBreederSize: '饲养员尺寸',
  bloomDaisyChance: '雏菊层级几率',
  bloomPoppyChance: '罂粟层级几率',
  bloomIrisChance: '鸢尾花层级几率',
  bloomLilyChance: '百合层级几率',
  bloomOrchidChance: '兰花层级几率',
  bloomCornflowerChance: '矢车菊层级几率',
  bloomDaisyBreedTime: '雏菊培育时间',
  bloomPoppyBreedTime: '罂粟培育时间',
  bloomIrisBreedTime: '鸢尾花培育时间',
  bloomLilyBreedTime: '百合培育时间',
  bloomOrchidBreedTime: '兰花培育时间',
  bloomCornflowerBreedTime: '矢车菊培育时间',
  weatherChaosFishingPower: '钓鱼力量',
  weatherChaosFishSizeMax: '最大鱼尺寸',
  weatherChaosFishSizeAverage: '平均鱼尺寸',
  weatherChaosFishingTime: '钓鱼时间',
  weatherChaosFishDoubleChance: '双倍鱼几率',
  weatherChaosIgnoreWeather: '忽略天气',
  weatherChaosFishChance: '中鱼几率',
  weatherChaosTreasureChance: '宝藏几率',
  weatherChaosAlgaeWeight: '海藻比重',
  weatherChaosDriftwoodWeight: '浮木比重',
  weatherChaosPlasticWeight: '塑料比重',
  weatherChaosTrashGain: '垃圾增益',
  summerFestivalBuildQueueSlots: '建造队列插槽',
  summerFestivalBuildQueueSpeed: '建造队列速度',
  summerFestivalMaterialGain: '材料增益',
  summerFestivalMaterialStackCap: '材料堆叠容量',
  nightHuntFindableIngredients: '可找到的成分',
  nightHuntIngredientSize: '原料尺寸',
  nightHuntMaxIngredients: '仪式成分',
  nightHuntBonusIngredientCount: '奖励成分',
  nightHuntBonusIngredientAmount: '奖励成分数量',
  nightHuntRitualStability: '仪式稳定性',
  nightHuntRitualSuccessChance: '仪式成功几率',
  nightHuntRitualHintChance: '仪式提示几率',
  nightHuntRitualFamiliarity: '仪式熟悉度',
  snowdownAttack: '攻击',
  snowdownHealth: '生命值',
  snowdownDefense: '防御',
  snowdownCritRating: '暴击率',
  snowdownBlockRating: '格挡率',
  snowdownLootRating: '战利品率',
  snowdownPetAttack: '宠物攻击',
  snowdownPetHealth: '宠物生命值',
  snowdownPetDefense: '宠物防御',
  snowdownPetCritRating: '宠物暴击率',
  snowdownPetBlockRating: '宠物格挡率',
  snowdownAllAttack: '全部攻击',
  snowdownAllHealth: '全部生命值',
};
