export default {
    day: '2023-10-23',
    content: {
        meta: [
            {
                type: 'bugfix',
                text: '227'
            }
        ],
        horde: [
            {
                unlock: 'hordeItems',
                type: 'bugfix',
                text: '226'
            }
        ]
    }
}
