export default {
  releasedOn: '发布于',
  changeCount: '更改了 {0} 项尚未解锁的内容',
  versionType: {
    major: '新的大版本',
    minor: '新版本',
    patch: '补丁'
  },
  type: {
    bugfix: '修复Bug',
    balance: '平衡',
    qol: '生活质量',
    clarity: '明确',
    info: '信息',
    context: '语境',
    new: '新',
    remove: '移除',
    change: '更改',
    accessibility: '辅助功能',
    appearance: '外观'
  },
  text: {
    addedFeature: '添加了功能',
    addedUpgrade: '添加了 {0} 新升级',
    addedPrestigeUpgrade: '添加了 {0} 新声望升级',
    addedGemUpgrade: '添加了 {0} 新宝石升级',
    addedBuilding: '添加了 {0} 新建筑',
    addedAchievement: '添加了 {0} 成就',
    secretAchievement: '添加了 {0} 秘密成就',
    addedHordeItem: '添加了 {0} 新装备物品',
    addedHordeSigil: '添加了 {0} 新印记',
    addedHordeHeirloom: '添加了 {0} 新传家宝',

    // v1.0.1
    0: 'Slighlty increased the amount of scrap gained at the early mining depths',
    1: 'Shows a hint for new players about not getting scrap when digging down',
    2: 'The building queue now shows progress of the currently constructed building',
    3: 'Buildings now stay in the list until the building is finished when reaching the maximum amount',
    4: 'Using the answer button now keeps the textbox focused',
    5: 'Explained the process of using cards on prestige',
    6: 'Upgrade lists now remember the last page you were on',
    7: 'Shows a hint for new players that reach a high zone without discovering monster parts',
    8: 'Added tooltips explaining depth dweller values',
    9: 'Achievements with a relic reward now state in the tooltip that the relic is given when completing the next achievement level',
    10: 'The school feature was never intended to feel like something you are forced to do for its rewards, but rather as an option to spend spare time for a little boost. I am making a few changes to the reward systems of this feature to make school more optional, especially in the early game where time skipping is much more valuable.',
    11: 'Golden dust gain reduced if your global level is below 250 (linear)',
    12: 'The "Know-it-all" achievement is now secret, which means it does not contribute to gem gain or "Overachiever"',
    13: 'Using the X button in literature no longer causes an error',

    // v1.1.0
    14: 'Last patch I already introduced some band-aid solutions to address the school system. While they did help a little in making the game less about grinding school all the time, they did not fix the underlying issues. This rework is aimed at reducing the amount of time pressure some players felt, and removing the grind aspects while still keeping school as an occasional option to get golden dust. After all, Gooboo is supposed to be a long-term idle game, not an active incremental. This was also a great opportunity to clean up the intransparent system of elo and grades.',
    15: 'Elo system removed',
    16: 'Grades no longer decay over time',
    17: 'You can now switch between all grades you already reached',
    18: 'Studying no longer gives golden dust as reward',
    19: 'Base book gain per subject',
    20: 'Grades no longer increase book gain',
    21: 'You can now buy students for rubies to gather more books for you',
    22: 'Studying time',
    23: 'You now only get grade progress when studying on your best grade, but can also lose progress for bad performance',
    24: 'Practice mode: here you can try the subject without time pressure and leave at any time, but get no rewards',
    25: 'Exam mode: You have 1m 15s to reach the best score you can. At the end, you get golden dust based on your performance and chosen grade',
    26: 'Exam passes, they are needed to take exams. Get more daily or by raising your global level, exceeding the capacity converts it to golden dust',
    27: 'Reduced the amount of grades (removed -- and ++, as well as F- and F+), and adjusted difficulty to be about 2/3 of a full grade lower (if you had a C-- before you can now expect to reach C+)',
    28: 'Math optimal score requirement',
    29: 'Pressing answer in the math minigame with an empty text field no longer counts as a mistake',
    30: 'Literature optimal score requirement',
    31: 'Literature now picks from a list of words instead of random letters, and makes you type sentences instead of words. Numbers, capitalization and special characters still exist for higher grades though!',
    32: '"Still learning" penalty amount',
    33: '"Still learning" penalty removed at global level',
    34: 'Your new grade is based on your old grade and elo, whichever was higher. Exam passes gained from previous global levels are awarded retroactively',
    35: 'Perfect exam results (2x optimal score) end school early and get you to the next grade immediately',
    36: 'Having extra time at the end of the history minigame does not yield bonus points, but now ends the lesson early',
    37: 'Added an extra hint to the cards feature for players who collected cards, but didn\'t prestige yet',
    38: 'Expanded the description for the depth dweller with a section about its speed',
    39: 'When out of exam passes, you can buy one for 35 sapphires',
    40: 'Added exam passes to the reward pool of some small events',
    41: 'Centered settings on the new game screen for smaller - medium display sizes',

    // v1.1.1
    42: 'Bonus exam golden dust reward per grade',
    43: 'Getting a perfect exam now only unlocks the next grade if you did it on your best grade',
    44: 'Exam pass capacity removed',

    // v1.1.2
    45: 'Changed the way savefile exports are handled. This should fix the exporting issues some players had',
    46: 'Added a notification if autosaving fails',
    47: 'The note hint can now be seen on the features icon',
    48: 'Added a notification when crops are ready',
    49: 'Card packs now show the feature they belong to',
    50: 'Quest tasks now show the feature they belong to',
    51: 'Active effects now show their remaining cooldown in the tooltip',
    52: 'Active effects now cool down at a reduced rate when their items are not equipped',
    53: 'Bosses now state their resistance against biological damage in the damage types tooltip',
    54: 'Added equipment loadouts',
    55: 'Reduced soul gain on prestige to 10% - 100% if you spent less than 8 hours on the current run',
    56: 'Changed the depth dweller formula and speed increasing upgrades to be a bit more consistent at all stages',
    57: 'Maximum dweller depth can now be reached',
    58: 'The depth dweller tooltip now shows how much time is needed to reach certain depths',
    59: 'Prestige reward increase interval',
    60: 'Fixed some rounding errors when values were at exactly 0',
    61: 'The literature minigame now ignores the first space character',
    62: 'Literature now awards partial scores for sentences, depending on how many characters you typed correctly',
    63: 'Slightly reduced the word count per sentence for literature',
    64: 'Exams now have a warning in their tooltip if your hourglass is (almost) full',
    65: 'You can now see the time left until you get your daily exam pass',
    66: 'Mining autoprogress is now explained in the settings',
    67: 'Added an option to see relative upgrade values',
    68: 'Total building and housing count can now be seen in the building list. Players with existing savefiles may need to finish a building to see the correct amount',
    69: 'Added the option to convert exam passes to golden dust',
    70: 'Explained premium crafting slots on the gem upgrade',
    71: 'The savefile name now includes the current day',
    72: 'Nostalgia decay per defeated boss',
    73: 'Reduced heirloom chance from most external sources',
    74: 'Minimum enemy defeats required to fight boss',
    75: 'Ritual tome soul chance per level',
    76: 'Base soul chance',
    77: 'Added some cheap early-game levels for a few gem upgrades',
    78: 'Added a button to unequip all items',
    79: 'Changed the wording of some equipment effects to be more accurate',
    80: 'No longer get a guaranteed 35 soul drop on zone 20',
    81: 'Heirloom drops are no longer guaranteed every 10 zones',
    82: 'Nostalgia effect is no longer capped at +50%',
    83: 'Changed the description of the "Deeprun" achievement to prevent confusion between meters and minutes',
    84: 'Soul increase per zone',
    85: 'Base soul amount',
    86: 'Base heirloom chance',
    87: 'Heirloom chance modifier per zone',
    88: 'Turning off note notifications now clears existing hints',
    89: 'Over the last few days, it became clear that quick 15-minute-prestiges were by far the best strategy to get souls in the horde. Runs that took a day could barely get twice the amount of souls, and existing elements to combat this are clearly not working. This is against the idle-focused philosophy of Gooboo, and while active gameplay should always be the most efficient option, it should not render other options of progress meaningless. These changes aim to reduce the gap between players with very fast prestiges and others who prestige one or two times a day.',

    // v1.2.0
    90: 'Fixed a rounding issue with corruption when it is at exactly 0',
    91: 'Fixed a bug where you could use loadouts to equip items you did not find yet',
    92: 'Fixed some treasure bonuses not applying',
    93: 'Made the global level description more clear',
    94: 'Fixed some equipment effects not working while dead',
    95: 'Fixed a bug where upgrading or deleting a treasure sometimes shows an error',
    96: 'Adjusted the prices of some endgame buildings for a more seamless transition with the new content',
    97: 'Fixed a bug in the depth dweller preview showing 0s to reach next depth',
    98: 'Added prestige currency gain to depth dweller preview',
    99: 'Gold coins now show their gain from food conversion',
    100: 'Upgrade descriptions now hide when the first level is bought',
    101: 'Added upgrade descriptions for most upgrades that unlock new game mechanics',
    102: 'Added a gold chance breakdown for crops',
    103: 'History dates now have text shadows for better readability',
    104: 'Reworded some tooltips and added a few new ones to the night hunt event',
    105: 'Slightly changed the UI',
    106: 'Added tooltips for buying treasure and fragments',
    107: 'Farm fertilizers now show their effect when shown as prize',
    108: 'The essence gain multiplier from your global level is now shown in the essence tooltip',

    // v1.3.0
    109: '一般任务现在有文本阴影以提高可读性',
    110: '设备现在可以在折叠视图中升级',
    111: '现在显示不活动的印记',
    112: '减少了与不同敌人战斗时移动 UI 元素的数量',
    113: '敌人现在需要时间重生',
    114: '当敌人无法生成时，现在可以获得怪物零件',
    115: '添加了小老板以及旧老板拥有的所有战利品',
    116: '老板不再掉落战利品，他们现在会增加小老板的战利品增益',
    117: '灵魂几率被移除，它们现在总是掉落',
    118: '成就现在只计算基本攻击造成的伤害',
    119: '修复了敌人统计数据达到无穷大时的错误',
    120: '前 8 小时内灵魂声望增益不再减少',
    121: '现在可以将空装载设置为您当前装备的物品',
    122: '有些印记会给敌人带来主动效果',
    123: '玩家主动效果分为战斗主动和实用主动',
    124: '战斗主动取代下一次基本攻击',
    125: '实用活性物质可以存储多种电荷',
    126: '添加了沉默状态效果，它可以防止使用战斗活性物质',
    127: '现在像其他统计数据一样显示眩晕抗性',
    128: 'Boss眩晕抵抗',
    129: '现在，你在 20 区或更高区域击败的每个 Boss 都会获得 -2 Boss 要求',
    130: 'Boss 要求现在的最大值为 50',
    131: '重生时间现在的最大值为 5 分钟',
    132: '增加了大多数传家宝效果',
    133: '为一些部落 UI 元素添加了文本阴影，以提高可读性',
    134: '印记现在显示它们开始出现的区域',
    135: '添加了塔楼',
    136: '怀旧现在会在您最初几次尝试中增加传家宝的几率，而不是随着时间的推移增加传家宝的几率',
    137: '价格标签视觉效果略有改变',
    138: '腐化的灵魂现在拥有可以通过击败首领来增强的能力',
    139: '更改了大部分升级并删除/添加了一些',
    140: '部分小活动奖励池新增塔楼钥匙',
    141: '稍微改变了高级制作槽的措辞，使它们更加准确',
    142: '基础骨骼',
    143: '每个区域的骨质增加',
    144: '清理区域不再增加骨骼容量',
    145: '首领不再掉落常规敌人战利品',
    146: '现在，敌人每次横冲直撞时都会获得 +1 眩晕抗性',
    147: '狂暴的敌人不再会减少攻击力',
    148: '修复了“敲木头”成就会计算总和而不是取最高值的错误',
    149: '更改了一些卡牌效果以匹配更改后的部落环境',
    150: '冷冻实验室现在每秒而不是每分钟滴答一次',
    151: '作物经验表明，某些作物的产量较少',
    152: '您现在可以单击发现的药水配方，将其设置为您当前的仪式',
    153: '大多数战斗活动已被更改为造成更少的伤害和更长的眩晕时间',
    154: '如果您获得了遗物，成就通知现在会一直保留到关闭为止',
    155: '由于新旧版本的部落存在较大差异，所有升级级别均被重置，并对其他形式的进度进行了一些调整。 不要忘记重新购买升级！',
    156: '降低了 3 级以上“更多骨头”宝石升级的效果',

    // v1.3.1
    157: '修复了如果你有空宝藏槽时游戏崩溃的问题',

    // v1.3.2
    158: '部落等级奖励现在也会影响灵魂容量',
    159: '恢复了 v1.3.0 中丢失的一般遗物',
    160: '对于那些在活动期间获得特殊遗物的人来说，无法将其追溯到你的保存文件。 如果您有v1.3.0之前的备份，可以在此版本中加载来恢复它们',
    161: '根据收集的灵魂减少部落的经验增益',
    162: '装备升级现在会在达到最高等级时显示',
    163: '小老板现在在小老板计时器工具提示中显示他们的战利品',
    164: '增加了最终任务“战斗训练”的要求',
    165: '怀旧的传家宝机会',
    166: '现在在等待敌人出现时可以看到下一个敌人的统计数据',
    167: '常规敌人工具提示现在指出击败 Boss 会重生所有敌人',
    168: '小Boss攻击倍数',
    169: '小Boss受到生物伤害',
    170: '修复了某些玩家显示 NaN 的损坏灵魂显示',
    171: '修复了一些徽章在灯光模式下难以读取的问题',

    // v1.3.3
    172: '添加嘲讽模式以加快到达Boss的速度',
    173: '小Boss现在也算作击败 4 个常规敌人',
    174: '修复了堆叠实用程序活动导致数字在灯光模式下难以读取的错误',
    175: '由于嘲讽模式的进度更快，更改了一件部落圣遗物的所需等级',
    176: '修复了购买“牛奶”声望升级时骨头计算的错误',
    177: '敌人的活动现在显示在印记上',
    178: '不受“所有资源增益”影响的资源现在在货币工具提示中注明',

    // v1.3.4
    179: '将所有依赖项更新到最新版本。 这可能会引入一些意想不到的错误，但也会加速未来的发布过程',
    180: '用随机数播种替换 RNG 预滚',
    181: '镐制作的反保存浮渣系统无法再通过使用少量矿石来规避',
    182: '头目和小头目现在拥有相同的印记，直到你击败他们',
    183: '保存文件现已编码并使用 .txt 结尾',
    184: '由于优化和其他变化，对于普通玩家来说，保存文件应该小约 30%',
    185: '现在，当保存文件加载或导入失败时，您会收到一条错误消息',
    186: '现在可以查看即将进行的升级级别',
    187: '功能列表现在应该在所有移动设备上完全可见',
    188: '修复了一些玩家在查看价格标签时出现的错误消息',
    189: '添加了新的统计数据以更快地打破师盾',
    190: '添加了向下/向上 10 米的按钮',
    191: '添加了前进/后退 10 个区域的按钮',
    192: '更改了桌面版本上的笔记 UI - 现在可以通过悬停/单击查看笔记',
    193: '添加了与游戏故事和角色无关的新型注释',
    194: '具有统计奖励的遗物现在显示统计数据来自的功能',
    195: '当产品在当前运行中未解锁时，产品现在有一个带有工具提示的指示器',
    196: '离线摘要现在始终显示在新版本上',
    197: '预订升级现在显示链接的升级',
    198: '艺术小游戏现在可以正常工作了',
    199: '通过书籍增加最高等级的升级现在总是在常规最高等级之后使用线性缩放',
    200: '添加了一个按键绑定以转到上一个功能',
    201: '现在，您从所有来源获得的怀旧之情是原来的 5 倍',
    202: '灵魂容量翻倍',
    203: '提高了大多数游戏中期声望升级的价格',
    204: '「红卡」奖励怪兽部分能力',
    205: '基骨容量',
    206: '将“损坏的秒表”装备从40区移至31区，大大增加了找到它的几率',
    207: '将“净化泉”设备从32区移至40区',
    208: '“怪物汤”升级每级骨骼增益增加',
    209: '修复了一些书籍升级无法在最高级别解锁的问题',
    210: '现在可以通过拖动来移动宝藏',
    211: '花园侏儒的描述现在指出需要将其放置在田野上才能获得奖励',
    212: '小活动现在对每个人来说都应该是一样的',
    213: '所有活动内容现在都基于随机种子',
    214: '所有消除眩晕的装备主动功能现在都可以在眩晕时使用',
    215: '基础黄金机会',
    216: '降低了游戏早期升级的黄金成本',
    217: '基因生长时间缩短',
    218: '基因产量增加',
    219: '基因金币几率增加',
    220: '“双”基因稀有掉落几率增加',
    221: '“虚无主义者”和“黑洞”成就现在正确地表明他们计算的是牺牲而不是奉献',
    222: '您现在可以在折叠视图中购买最大升级',
    223: '基因经验增加',
    224: '添加了一个实验设置来显示资源增益计时器',
    225: '镐制作工具提示现在解释矿石品质',

    // v1.3.5
    226: '如果您的加载名称包含表情符号，游戏现在应该能够再次保存',
    227: '修复了发生迁移时离线摘要的备份中断问题',

    // v1.3.6
    228: '修复了加载保存文件时未恢复种子数的错误',
    229: '修复了黄玉在满了的时候显示到达下一个容量的时间',

    // v1.4.0
    230: '现在，基因从某些作物水平的固定列表中提取，而不是随机选择',
    231: '基因现在具有立即且可升级的效果',
    232: '引入了 DNA，它是在作物等级提升时获得的，可用于升级基因',
    233: '增加了大部分农作物的生长时间，同时也增加了大部分奖励',
    234: '在您购买第一个花园侏儒之前，农作物的生长时间和产量现在会大大减少',
    235: '卡牌现在具有声望效果，而不是提供资源',
    236: '过度生长 现在已取得部分进展',
    237: '改变了大多数肥料效果以进一步缩短生长时间',
    238: '降低每株作物的肥料成本',
    239: '农场建筑现在显示出它们对田间作物的影响',
    240: '由于生长处理方式的变化，田间所有作物的生长计时器都被重置',
    241: '作物统计数据现在显示肥料造成的变化',
    242: '农场现在每 5 秒滴答一次，而不是每分钟一次',
    243: '如果农作物仍在田里，农作物威望现在会被禁用',
    244: '增强现在需要一定数量的条，而不是有机会成功',
    245: '现在制作镐的最低质量要求为 0.1%',
    246: '深度居民现在在达到极限后继续挖掘奖励深度',
    247: '增益计时器现在对所有人可见',
    248: '添加了新的实验设置以显示货币标签',
    249: '增益计时器现在可以与显示每小时增益的货币一起正常工作',
    250: '添加了新的基于网络的游戏版本，无需互联网连接即可下载和玩',
    251: '农场经验现在根据当前作物水平和最高作物水平之间的差异给出',
    252: '基地农场经验',
    253: '改变了大多数作物的罕见掉落几率，它们在免费作物上应该不太常见，而在有金币成本的作物上更常见',
    254: '为大多数免费作物增加了黄金成本',
    255: '卡片现在减少了很多怀旧之情',
    256: '特定卡牌的腐败增加',
    257: '深度居民基本速度',
    258: '基础声望增益',
    259: '降低了一些游戏后期增强功能的威力',
    260: '增加了金条熔炼所需的时间',
    261: '每级“钻燃料”居民速度（只是乘数，线性比例保持不变）',
    262: '改变了大部分升级效果',
    263: '活动宝物的效果威力',
    264: '村庄物质增益基数效应',
    265: '调换了木材和植物纤维的位置',
    266: '大多数非粮食资源收益已减少至 66% - 80%',
    267: '信仰能力',
    268: '增加资源增益的升级效果较小，尤其是在更高级别',
    269: '现在可以多次获得几率超过 100% 的稀有掉落物',
    270: '修复了导致宝藏对等级和类型使用相同值的错误',
    271: '喷水器生长速度增加',
    272: '洒水器现在可以让植物过度生长 +250%',
    273: '信仰增益减少至 50%',
    274: '添加了 2 个新的系统注释来解释游戏机制',
    275: '设备现在在其工具提示中显示掌握点增益',
    276: '当增益以较高的数字减少时，颜色现在会显示提示',

    // v1.4.1
    277: '深度居民最低速度',
    278: '对于大多数玩家来说这应该是速度的提升，尤其是在游戏早期',
    279: '虽然上次更新确实达到了防止玩家过快到达内容结尾的目标，但早期级别的玩家也受到了附带损害。 这些更改旨在恢复早期级别（1-4）熟悉的村庄体验，同时保持后续内容（后期 5 级或更高级别）当前的村庄速度',
    280: '大多数资源增益被提高到旧值或新旧值之间的平均值',
    281: '从指数缩放到线性缩放的升级现在使用混合公式。 最终升级值应该是旧值的75%左右',
    282: '从“学校”建筑解锁的最大升级级别',
    283: '“基础”升级现在对植物纤维的效果增强',
    284: '在解锁相关产品之前您不能再牺牲资源',
    285: '现在，产品会根据当前运行中获得的产品数量随着时间的推移而生成',
    286: '5 级建筑成本增加至 1.5 倍 - 5 倍',
    287: '6 级建筑成本增加至 6 倍 - 20 倍',
    288: '7 级建筑成本增加至 22.5 倍 - 45 倍',
    289: '每力量的资源增益增加',
    290: '修复了导致构建 UI 元素出现在错误位置的错误',
    291: '修复了解锁后升级不立即可见的问题',
    292: '缩短了大多数作物的生长时间，尤其是那些生长时间较长的作物（新的生长时间在 1 小时到 2 天之间）',
    293: '洒水器过度生长增加',
    294: '增加大部分肥料效果',
    295: '增加了 HO-0039 和 HO-0040 卡的效果',
    296: '实质性超额收益',
    297: '现在，第 4 级产品的每次购买成本都会增加',
    298: '如果成就消息包含遗物，则添加文本阴影',
    299: '10级后增加了“策划者”成就的要求',
  },
  v: {
    1: {
      0: '一个爆炸性的开始',
      1: '校长被替换!',
      2: '生态友好',
      3: '永无休止的Boss',
      4: '缓慢而稳定',
      5: '???',
    }
  }
}
