export default {
  cardPack: '卡包',
  cardsSuffix: ' 卡片',
  notFound: '未找到卡片',
  fullCollectionReward: '集齐奖励',
  canFind: '这张卡可以在以下卡包中找到',
  cannotFind: '在您当前可用的卡包中找不到此卡',
  active: '激活的卡片',
  equipped: '装备的卡片',
  onActive: '当激活时',
  onDuplicate: '当重复',
  activeDescription: '可以装备重复的卡牌，并将在下一个声望时消耗卡片以激活其效果。 这些效果会一直保持有效，直到您再次获得声望为止。',
  equipTutorial: {
    description: '您收集了第一张卡片！ 大多数卡片在激活时都会产生效果，要启用它，您必须执行以下操作:',
    1: '收集一张卡的副本',
    2: '解锁您的主要功能之一的声望',
    3: '进入声望菜单并装备重复卡片（必须与卡右下角所示的功能相同）',
    4: '使用装备的卡片执行声望',
    5: '该卡片将被消耗，其效果将一直有效，直到您再次声望为止'
  },
  card: {
    'MI-0001': '基本装备',
    'MI-0002': '未使用的镐',
    'MI-0003': '镐箱',
    'MI-0004': '点燃保险丝!',
    'MI-0005': '进入深处',
    'MI-0006': '头盔架',
    'MI-0007': '掩护!',
    'MI-0008': '努力工作',
    'MI-0009': '镐或铲子?',
    'MI-0010': '太小了',
    'MI-0011': '积极进取的工人',
    'MI-0012': '职场意外',
    'MI-0013': '临时存储',
    'MI-0014': '装满的箱子',
    'MI-0015': '表面上看',
    'MI-0016': '萃取',
    'MI-0017': '运输',
    'MI-0018': '质量控制',
    'MI-0019': '回收工厂',
    'MI-0020': '液压机',
    'MI-0021': '精炼实验',
    'MI-0022': '机器人装配',
    'MI-0023': '卡住的镐',
    'MI-0024': '矿井入口',
    'MI-0025': '引爆石堆',
    'MI-0026': '矿井',
    'MI-0027': '地表岩石',
    'MI-0028': '扰乱蝙蝠',
    'MI-0029': '令人毛骨悚然',
    'MI-0030': '稀有发现',
    'MI-0031': '地下湖',
    'MI-0032': '可燃液体',
    'MI-0033': '地下坟场',
    'MI-0034': '潜伏的蜘蛛',
    'MI-0035': '没有出路',
    'MI-0036': '地震',
    'MI-0037': '有毒气体',
    'MI-0038': '水晶尖刺',

    'VI-0001': '巨大的巨石',
    'VI-0002': '隐藏的宝石',
    'VI-0003': '滴水石洞',
    'VI-0004': '两条路',
    'VI-0005': '洞穴花卉',
    'VI-0006': '户外露营',
    'VI-0007': '操场',
    'VI-0008': '安静的区域',
    'VI-0009': '愤怒的邻居',
    'VI-0010': '带花园的房子',
    'VI-0011': '建造一个家',
    'VI-0012': '野营之旅',
    'VI-0013': '客厅',
    'VI-0014': '狗屋',
    'VI-0015': '屋顶花园',
    'VI-0016': '世纪树',
    'VI-0017': '树木盗窃',
    'VI-0018': '社区花园',
    'VI-0019': '重新造林',
    'VI-0020': '砍伐森林',
    'VI-0021': '原木卡车',
    'VI-0022': '研究实验室',
    'VI-0023': '实验',
    'VI-0024': '病情分析',
    'VI-0025': '成型棒材',
    'VI-0026': '制钉厂',
    'VI-0027': '老图书馆',
    'VI-0028': '发条',
    'VI-0029': '烹饪机器人',
    'VI-0030': '远程车库',
    'VI-0031': '发电机',
    'VI-0032': '太阳能板',
    'VI-0033': '自动驾驶汽车',
    'VI-0034': '消防员',
    'VI-0035': '警察局',
    'VI-0036': '照亮道路',
    'VI-0037': '拯救一只狗',
    'VI-0038': '救护车',
    'VI-0039': '救生员',

    'HO-0001': '潜伏的蛇',
    'HO-0002': '深洞',
    'HO-0003': '喷发的火山',
    'HO-0004': '迷失',
    'HO-0005': '永远冰冻',
    'HO-0006': '岩浆室',
    'HO-0007': '背包检查',
    'HO-0008': '墙体施工',
    'HO-0009': '监控摄像头',
    'HO-0010': '蘑菇指南',
    'HO-0011': '电子围栏',
    'HO-0012': '巨型炸弹',
    'HO-0013': '火箭发射器',
    'HO-0014': '剑剑',
    'HO-0015': '毒箭',
    'HO-0016': '导弹',
    'HO-0017': '斧头投掷者',
    'HO-0018': '威力笔',
    'HO-0019': '弹射',
    'HO-0020': '枪刃',
    'HO-0021': '太阳光线',
    'HO-0022': '锯片发射器',
    'HO-0023': '超强腐蚀性',
    'HO-0024': '自然覆盖',
    'HO-0025': '森林冥想',
    'HO-0026': '金属探测器',
    'HO-0027': '射弹护盾',
    'HO-0028': '力场',
    'HO-0029': '雷达',
    'HO-0030': '间谍',
    'HO-0031': '不允许腐败',
    'HO-0032': '液体过滤器',
    'HO-0033': '洗衣机',
    'HO-0034': '用火杀死它',
    'HO-0035': '净化光束',
    'HO-0036': '遏制腐败',
    'HO-0037': '进垃圾桶！',
    'HO-0038': '奇怪的仪式',
    'HO-0039': '吸尘器',
    'HO-0040': '科学的力量',
    'HO-0041': '只需用微波炉加热即可',

    'FA-0001': '摘苹果',
    'FA-0002': '南瓜大赛',
    'FA-0003': '花店',
    'FA-0004': '办公厂房',
    'FA-0005': '稻草人',
    'FA-0006': '人工降雨',
    'FA-0007': '补给列车',
    'FA-0008': '食品运输',
    'FA-0009': '温暖温室',
    'FA-0010': '水果冰沙',
    'FA-0011': '面包店促销',
    'FA-0012': '冰淇淋卡车',
    'FA-0013': '快餐订单',
    'FA-0014': '盛宴',
    'FA-0015': '糖果乐园',
    'FA-0016': '下午茶时间',
    'FA-0017': '酿酒',
    'FA-0018': '饼干和咖啡',
    'FA-0019': '橙色蔬菜',
    'FA-0020': '辛辣果味红色',
    'FA-0021': '雏菊黄',
    'FA-0022': '植物绿化',
    'FA-0023': '蓝莓丛',
    'FA-0024': '紫色蘑菇',
    'FA-0025': '虫粉',
    'FA-0026': '新鲜酸橙',
    'FA-0027': '蝴蝶翅膀',

    'GA-0001': '瓢虫',
    'GA-0002': '七彩羽毛',
    'GA-0003': '制作柠檬水',
    'GA-0004': '藻类',
    'GA-0005': '艺术博物馆',
    'GA-0006': '铅笔画',
    'GA-0007': '钢笔系列',
    'GA-0008': '喷漆',
    'GA-0009': '调色板',
    'GA-0010': '壁画',
    'GA-0011': '艺术家签名',
    'GA-0012': '调漆',
    'GA-0013': '现代艺术品拍卖',
    'GA-0014': '肖像',
    'GA-0015': '回收站',
    'GA-0016': '披萨递送',
    'GA-0017': '包裹快递',
    'GA-0018': '校车',
    'GA-0019': '制鼓工厂',
    'GA-0020': '货船',
    'GA-0021': '搬家公司',
    'GA-0022': '无人机送货',
    'GA-0023': '拖车',

    'GE-0001': '红宝石铁路',
    'GE-0002': '祖母绿矿',
    'GE-0003': '沉没的宝藏',
    'GE-0004': '奢华腕表',
    'GE-0005': '危险的三角形',
    'GE-0006': '金刚石工具',
    'GE-0007': '安防措施',

    'EV-0001': '公平',
    'EV-0002': '建筑竞赛',
    'EV-0003': '炙烤',
    'EV-0004': '生日聚会',
    'EV-0005': '旧货出售',
    'EV-0006': '加油站',
    'EV-0007': '本地商户',
    'EV-0008': '电视宾果游戏',
    'EV-0009': '赌场',
    'EV-0010': '???',
    'EV-0011': '银行保险箱',
    'EV-0012': '利润！',
    'EV-0013': '信用卡',
    'EV-0014': '沙漠',
    'EV-0015': '滑翔伞',
    'EV-0016': '丛林之谜',
    'EV-0017': '搁浅的乌龟',
    'EV-0018': '花卉育种',
    'EV-0019': '植物样本',
    'EV-0020': '鱼叉捕鱼',
    'EV-0021': '蜗牛赛跑',
    'EV-0022': '孤岛',
    'EV-0023': '夏日之歌',
    'EV-0024': '舒适的沙发',
    'EV-0025': '讨厌的山',
    'EV-0026': '夜晚的城市',
    'EV-0027': '热苹果汁',
    'EV-0028': '神秘的汤',
    'EV-0029': '秘泉',
    'EV-0030': '做雪人',
    'EV-0031': '冬季运动',
    'EV-0032': '城堡灯',
    'EV-0033': '灯泡店',

    'XD-1337': '测试卡'
  },
  collection: {
    minersAndEquipment: '矿工和装备',
    scrapLogistics: '废料物流',
    caveLocations: '洞穴位置',
    dangersInTheDark: '黑暗中的危险',
    neighborhood: '邻里',
    plantsInTheCity: '城市里的植物',
    industrialRevolution: '工业革命',
    maintainingSafety: '维护安全',
    dangerousWeapons: '危险武器',
    supplyAndSupport: '供应与支持',
    againstTheCorruption: '反对腐败',
    feedingTheWorld: '喂养世界',
    organicDyes: '有机染料',
    artDisplay: '艺术展示',
    deliveryService: '送货服务',
    preciousJewelry: '贵重珠宝',
    weekendTrip: '周末旅行',
    tropicalParadise: '热带天堂',
    coldTimes: '寒冷时期',
  },
  pack: {
    intoDarkness: '进入黑暗',
    drillsAndDepths: '钻孔和深度',
    hotStuff: '热门内容',
    dangerZone: '危险区',
    meetingNewPeople: '结识新朋友',
    darkCult: '黑暗教派',
    technologicalAdvancement: '科技进步',
    rookieOnTheBattlefield: '战场上的菜鸟',
    spiritualSuccess: '精神上的成功',
    oldMemories: '旧的记忆',
    taintedWorld: '被污染的世界',
    bountifulHarvest: '丰收',
    juicyYields: '多汁的产量',
    insectWorld: '昆虫世界',
    beesAndFlowers: '蜜蜂和鲜花',
    newArtist: '新艺术家',
    inspiringCreations: '鼓舞人心的创作',
    goodDeal: '好交易',
    connectedLine: '连接线',
    feelingLucky: '感觉很幸运',
    investorsDream: '投资者梦想',
    greenThumb: '园艺高手',
    fishingForFun: '钓鱼为乐',
    charmingShip: '迷人的船',
    midnightAnomaly: '午夜异常',
    icyWonderland: '冰雪仙境',
    sparksOfJoy: '欢乐的火花',
  }
};
