export default {
    price: 1000,
    light: {
        primary: '#7619D2',
        secondary: '#424242',
        accent: '#C182FF',
    },
    dark: {
        primary: '#7619D2',
        secondary: '#424242',
        accent: '#C182FF',
    }
}
