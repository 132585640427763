export default {
  text: {
    meta_0: 'Increase global level. Unlock new features. See requirement in feature list.',
    meta_1: 'Expand resource capacity. Buy bigger upgrades. Avoid resource waste.',
    meta_2: 'Leave whenever you want. Everything here keeps working.',
    meta_3: 'Multiple features. All keep working at the same time.',
    meta_4: 'Do not neglect features. All important.',
    meta_5: 'Global level. Need to unlock new features. Progress in features to increase.',
    meta_6: {
      title: 'Back up your savefile!',
      text: 'Browser-based savefiles are not perfectly reliable, so backup your savefile often! Only one small accident is enough to wipe your progress, such as browser crash, power outage, the browser mistaking the game for a tracker or a bug in the game. Download a backup whenever the hint shows up and when a new update is released, and you should be safe. iOS users and itch players are at a higher risk of savefile loss and should change the backup hint to "Common"'
    },
    meta_7: {
      title: 'Tooltips',
      text: 'Many elements in the game display additional information if you hover over them with your mouse. Players on mobile devices can tap and hold to achieve the same result',
      buttonTooltip: 'Click to toggle'
    },

    mining_0: 'Use pickaxe. Dig deeper.',
    mining_1: 'Collect scrap. Buy upgrades.',
    mining_2: 'Reach new depths. Reveal new upgrades.',
    mining_3: 'Check settings. Descending can be automated.',
    mining_4: 'Extract ore. Buy more upgrades.',
    mining_5: 'Dig deeper. Find more ore.',
    mining_6: 'Buy crafting station. Make better pickaxes.',
    mining_7: 'Keep crafting. Sometimes good. Sometimes bad.',
    mining_8: 'More slots. Better pickaxes. Compress ore. Need more ore. Less bad pickaxes.',
    mining_9: 'New ore. Use both for crafting. Best result.',
    mining_10: 'Dig deeper. Find other ores.',
    mining_11: 'Reduce toughness. Advance further.',
    mining_12: 'Unlock dweller. Prepare for new cycle. Needs time though.',
    mining_13: 'Dig too deep. Some ores disappear. Need specific depths. Then they come back.',
    mining_14: 'New ore. Another one. New ores better. But impure.',
    mining_15: 'Break same rock 1000 times. At 50m or deeper. Find something new.',
    mining_16: 'Hole really deep now. Don\'t fall in. Probably kills you.',
    mining_17: 'One hole. One stone.',
    mining_18: 'Found smeltery. Very hot. Needs ores. And new materials. But results good.',
    mining_19: 'Copper gone too. Do not worry. It comes back. Maybe.',
    mining_20: 'Another rare earth. Each one has their own condition. And use.',
    mining_21: 'Next ore. Iron this time. Very impure. Need to compress a lot.',
    mining_22: 'Found coal. Not ore. No crafting. No upgrades. Coal useless?',
    mining_23: 'Coal useful. Special upgrades. Improve damage. Or green crystals.',
    mining_24: 'Hole too deep. Can\'t see bottom. Only dark.',
    mining_25: 'New enhancements. Need 10 bars. Make pickaxe better.',
    mining_26: 'Another ore. Normal by now. Use for crafting. Need lots of purity.',
    mining_27: 'Forge ember. Get next cycle. Then useful. Makes smeltery faster.',
    mining_28: 'Keep forging bars. Give different enhancements.',
    mining_29: 'Use ember. Lose smeltery speed. Think about choice.',
    mining_30: 'Found resin. From tree. Makes pickaxes better. But takes long to get. Multiple hours.',
    mining_31: 'Dont\'t drop resin. Sticks to everything. Stay there for days.',
    mining_32: 'Wonder how tree survives. Dark cave. Not ideal environment. But still grows.',
    mining_33: 'Last note for now. Ran out of paper. Maybe come back. And write more.',

    village_0: 'Lonely creature. Send it to collect.',
    village_1: 'Build hut. Attract more creatures.',
    village_2: 'Creatures look weird. Brown and hairy.',
    village_3: 'Peaceful farm. Crops grow. Produce food.',
    village_4: 'Fruit trees. Also produce food.',
    village_5: 'Dark mine is scary. But give metal.',
    village_6: 'Creatures are community now. Dance and celebrate.',
    village_7: 'Creature unfamiliar with house. And door. But likes it.',
    village_8: 'Storage very important. Often capacity the problem. Not price.',
    village_9: 'Working creatures pay taxes. Consume food. Give coin.',
    village_10: 'Creatures eyeing on coins. Must keep coin safe. In safe.',
    village_11: 'Creatures take water from hole. Use arms to grab bucket. With water.',
    village_12: 'Pretty flowers. Creatures seem to not care. But still pretty.',
    village_13: 'Town of creatures. They dance again. Seems important to them.',
    village_14: 'Creature got used to house. Opens window. Big smile.',
    village_15: 'Creatures smart. Did not expect. Only say ugh.',
    village_16: 'Fish look pretty. Want to eat them. But can\'t. Not now.',
    village_17: 'Smelt sand. Can be shaped. Dangerous work.',
    village_18: 'Creatures believe. In world\'s end. In rebirth.',
    village_19: 'Huge tower. Full of books. And paper. Creatures look at it. With admiration.',
    village_20: 'Creatures seem skeptical. Don\'t like this one. Put sign "NO HOMEWORK". Creatures change mind.',
    village_21: 'Own government. They dance again. But not all. Some worried.',
    village_22: 'Mighty obelisk. Intimidating size. Massive storage.',
    village_23: 'Creatures believe. Not only in rebirth. Also in sacrifice.',
    village_24: 'Creatures live good. Worry about entertainment. Not food.',
    village_25: 'Sharper axes. Cut hardwood. And regular wood.',
    village_26: 'Be careful. Explosions dangerous.',
    village_27: 'Creatures really live good. Can afford luxury.',
    village_28: 'Relaxing lake. Full of fish.',
    village_29: 'Knowledge lost. Over time. Discover it again.',
    village_30: 'Small creatures. Love this one.',
    village_31: {
      title: 'Moving queue elements',
      text: 'You can change the order of elements in the building queue by dragging them around'
    },

    horde_0: 'Hordes of monsters. Block path. Only one way to progress. Kill enough. Then kill leader.',
    horde_1: 'One group dead. But more appear.',
    horde_2: 'Advance further. Get more bones.',
    horde_3: 'Equip items. Improve stats. Use abilities in combat.',
    horde_4: 'Fight monsters. Find new equipment.',
    horde_5: 'Fight in higher zones. Find better equipment. Also find chance higher.',
    horde_6: 'Reach zone 10. Defeat all enemies. Find new currency.',
    horde_7: 'Monsters look scary. Don\'t worry. Always respawn.',
    horde_8: 'No end in sight. Monsters everywhere.',
    horde_9: 'Monsters get stronger. But predictable.',
    horde_10: 'Strange souls. Corrupted and unusable. Need to purify.',
    horde_11: 'Can get souls. Every miniboss. Always get.',
    horde_12: 'Higher zone boss. Hold more souls. Also get more.',
    horde_13: 'Care about sigils. Sometimes good to change equipment.',
    horde_14: 'Kill miniboss. Find heirloom. Belonged to them. Now helps you. Forever.',
    horde_15: 'Higher zone. More sigils. Both amount and variety.',
    horde_16: 'So it begins. Corruption makes them stronger.',
    horde_17: 'Get purifier. Defeat the corruption.',
    horde_18: 'Gain corrupted flesh. Slowly over time. Reduce corruption.',
    horde_19: 'Higher zone. Find different heirlooms.',
    horde_20: 'Big collection. Of equipment. Experiment which is best.',
    horde_21: 'Corruption dangerous. Don\'t let it get big. Not just attack and health.',
    horde_22: 'Circle of ash. Helps with corruption.',
    horde_23: 'Discover more. More equipment. More heirlooms. More sigils.',
    horde_24: 'Defeat boss. With equipment. Gain mastery.',
    horde_25: 'Better equipment. Need higher zone boss. For mastery.',
    horde_26: 'Mastery good. Unlock improvement. For equipment.',
    horde_27: 'Improve heirlooms. All of them.',
    horde_28: 'Paper low. Need to get more.',
    horde_29: 'Find more heirlooms. At once. Very strong.',
    horde_30: 'Out of paper. Must come back. More to tell.',

    farm_0: 'Plant carrot. Wait. Then harvest.',
    farm_1: 'Get vegetables. Buy other seeds.',
    farm_2: 'Different crop type. Different harvest. Need them all.',
    farm_3: 'Overgrow crops. Multiply harvest. Needs upgrade.',
    farm_4: 'Unlock more tiles. Plant more.',
    farm_5: 'Get gnomes. Place on farm. Find rare currency.',
    farm_6: 'Harvest specific crops. Get rare drops.',
    farm_7: 'Careful about capacity. Hard limit for rare drops.',
    farm_8: 'Place sprinkler. Row grows faster.',
    farm_9: 'Petals special. Improve flower yield. Hoard them.',
    farm_10: 'Bugs disgusting. Nobody likes them. Unfortunately still needed.',
    farm_11: 'Beautiful wings. Like little fragile birds.',
    farm_12: 'Lectern good. Place it. Triple experience in column.',
    farm_13: 'Bugs stinky. Why use to make perfume? Why??? Terrible idea!',
    farm_14: 'Small lucky bugs. Also not stinky. Seem nice.',
    farm_15: 'Farm is peaceful. Almost forget mass killing at horde.',
    farm_16: 'Hunter of the garden. Keeps bugs in check.',
    farm_17: 'Pinwheel interesting. Place unique crops nearby. 8 spots around pinwheel. Find more gold. On the entire field.',
    farm_18: 'More fertilizers. More ways to spend gold and gems.',
    farm_19: 'Litte bees. Always working. And numerous.',
    farm_20: 'Flag complicated. Improves crops of a type. Type depends on position. Better read manual.',
    farm_21: 'Golden rose. Has rare petals. Very valuable.',

    gallery_0: 'Play with colours. Make painting. Dream big.',
    gallery_1: 'Always experiment. Try something new. Makes you better.',
    gallery_2: 'Recycle old paper. Change into something new. Something colorful.',
    gallery_3: 'Convert into more. For same cost. Always useful.',
    gallery_4: 'Filter old paper. Before recycling. Get more.',
    gallery_5: 'Think. Get new ideas. Keep good ones. Make own style.',
    gallery_6: 'Old trash can. Stinky. But useful.',
    gallery_7: 'Better tools for painting. Better results. More colorful.',
    gallery_8: 'Sell your hard work. Money will be useful.',
    gallery_9: 'Get drums. Need packages. And luck. But worth it.',

    gem_0: 'Generate gems. Takes time. Buy premium upgrades.',
    gem_1: 'Be patient. Think about purchases. Gems take long to get.',

    achievement_0: 'Reach goals. Get achievements. Generate gems faster.',

    school_0: 'Learn in school. Answer correctly. Get good grades.',
    school_1: 'Be an eager student. Receive golden dust. Can skip time.',
    school_2: 'Get new books. Every hour. Even more with more subjects.',
    school_3: 'Also learn to write. Get more books.',
    school_4: 'History is important. Remember dates. Even more books.',

    relic_0: 'Get relics. Helpful forever.',

    card_0: 'Buy card packs. Collect cards. Get bonuses.',
    card_1: 'Complete card collection. Get more bonuses.',

    event_0: 'Participate in events. Limited time. Get rewards.',
    event_1: 'Big event. Progress to get event tokens. Then trade them for rewards.',
    event_2: 'Spend topaz on helpful upgrades. But do not get topaz during big event. Replaced by other currency.',
    event_3: 'Buy special items. With topaz.',
    event_4: 'Play bingo. Get reward for one or multiple bingos. Also get reward for getting certain numbers.',
    event_5: 'Spin the wheel. Get reward.',
    event_6: 'One of three choices. Save for permanent upgrade. Invest topaz for interest. Borrow topaz and pay back later.',
    event_7: 'Buy producers. Get light.',
    event_8: 'Buy enough producers. Get special upgrades.',
    event_9: 'Buy candles. Get more light. Also get soot for special upgrades.',
    event_10: 'Get enough light. Receive tokens as reward.',
    event_11: 'Drag flower to breeder. Get more flowers over time.',
    event_12: 'Merge two flowers. Of same tier. Get a better one.',
    event_13: 'Sell flowers. For blossoms. Buy upgrades.',
    event_14: 'Reach higher flower tiers. Receive tokens as reward.',
    event_15: 'Be patient. Catch fish over time. Or other things.',
    event_16: 'Look at fish. Each one has their own conditions.',
    event_17: 'New location. New fish. And other changes.',
    event_18: 'Catch biggest fish. Receive tokens for new size records.',
    event_19: 'The big summer festival. Build structures. Complete quests.',
    event_20: 'Collector helpful. Automatically collects resources on nearby tiles. But be careful. Blocks tile it is placed on. Like all buildings.',
    event_21: 'Don\'t feel bad about destroying buildings. Space is limited.',
    event_22: 'Main stage important. Unlocks new buildings. Can even be destroyed after.',
    event_23: 'Complete more quests. Receive more tiles. Can choose which one to have. But needs to be connected to existing ones.',
    event_24: 'Some buildings can process. Select building and choose a recipe.',
    event_25: 'Complete quests. Receive tokens as reward. But takes resources.',
    event_26: 'Watch currencies. When they look unusual, hover over them for ingredients.',
    event_27: 'Use ingredients. Perform rituals.',
    event_28: 'Find new potion. Gain essence. Perform same ritual again to increase potion level. And get more tokens.',
    event_29: 'Successful ritual. Receive tokens as reward. Try different ingredients for more results.',
    event_30: 'Start snowball fight. Takes snowballs. Win to receive helpful items.',
    event_31: 'Pets helpful. Also throw snowballs. But weaker than player.',
    event_32: 'Careful about item choice. Can be changed. But takes snowballs.',
    event_33: 'Win fights. Receive tokens as reward. And snowballs.',

    treasure_0: 'Buy treasure. Give helpful effects.',
    treasure_1: 'Break treasure. Effects gone. But get fragments.',
    treasure_2: 'Use fragments. Upgrade treasure. Better effects.',

    cryolab_0: 'Freeze features. Get prestige currency passively.',
    cryolab_1: 'Level up features. Get more prestige currency. Also get a little prestige currency passively. Even when not frozen.',

    general_0: 'Do quests. Receive more notes. Get relic when done.',
    general_1: 'It is nice to see you responding to our signals. My name is Grobodal, and I am here to show you our wonderful world.',
    general_2: 'Great to see the old settlement thriving again. You seem to have a really positive impact on this world, so let me tell you something. If you dwell deep in the mines, you can find something vital to your progression.',
    general_3: 'Ah, the old drill. We used to build these to explore the underground of our world, but that was a long time ago.',
    general_4: 'With the school standing again, the creatures can now live an educated life. Since that\'s taken care of, how about a little challenge? If you can get 10 billion scrap in 15 minutes or less, i\'ll give you this relic of the old times.',
    general_5: 'You made it, amazing! Here is the relic, as I promised. It is a very old one, my ancestors used these to illuminate cave systems. I\'m sure you can find a use for it.',
    general_6: 'So even in this dangerous environment you have no problem making progress. I hope my notes helped with that, even though my handwriting can be hard to read sometimes. Anyway, how about a little challenge? Think you can accumulate a total of 5 billion damage without leaving the first zone?',
    general_7: 'Seems like that was hardly a challenge at all for you. So how about another one? Crafting pickaxes is a crucial part of advancing in the mines, how far do you think you can get without crafting a single one? 40 meters sound possible to me.',
    general_8: 'Exploring the underground feels amazing. I know because I have done so myself for a very long time. I used an automated drill though, so digging down 40 meters with just a basic pickaxe is an impressive feat!',
    general_9: 'Be careful of the corruption, it seems managable at first, but don\'t let it get out of control. When the corruption in an enemy rises to higher levels, they become practically unbeatable.',
    general_10: 'With their own government, these creatures should be self-sufficient. But I think they would still prefer your guidance, it has worked out great so far. But let\'s talk about something else. To receive my relic, you need to accumulate a large amount of corrupted souls.',
    general_11: 'That should be enough souls to understand the significance of this relic. It is a heart from a very important time in our history, where we had to face a big challenge.',
    general_12: 'Nice to see potatoes grow again. The seed box seems to hold a lot of seeds from the old times. We should bring these beautiful plants to the village and spread the relaxing atmosphere there as well.',
    general_13: 'Grow the same crop multiple times, and its level increases. You can technically prestige as soon as level 4, but try reaching level 7 on a crop this time.',
    general_14: 'Plants grow exceptionally well on your farm. Growing them underground can be quite a challenge, not all types of plants respond well to artificial sunlight. You should be happy for the fertile soil and abundant daylight.',
    general_15: 'That crop was massive! With that big yield, you should have no problem unlocking more seeds. Take a total of 7 seeds from the seed box, and I\'ll give you a relic from an old plant that used to be very common on our planet.',
    general_16: 'This is a rotten leaf from a hazelnut tree. In autumn, these leaves used to be everywhere. Perhaps you can use it on your farm to make the soil even more fertile. Just bury it under your farm, that should do the job.',
    general_17: 'You may be wondering why everything down here is full of scrap. It\'s a long story about an infinite need of resources in a finite world.',
    general_18: 'When you run out of resources, it doesn\'t matter how powerful you are. Learn to thrive with few resources, it is an important skill to learn.',
    general_19: 'The village seems to have a different underground structure than the mines. Clean stone can be found everywhere, and not a single bit of scrap.',
    general_20: 'This is a specialized tool to break through the scrap-filled mines with ease. I have used one of these myself and can confirm their effectiveness.',
    general_21: 'Mastering your equipment can take a long time, but it is worth it. This world presents tough challenges, you\'ll need every bit of help you can get.',
    general_22: 'To grow such a massive plant, you must have learned about this world and adapted. That attitude will get you far in our world.',
    general_23: 'Even in the village you managed to reach new magnitudes of civilization. Trust me, the creatures are more than happy about this.',
    general_24: 'This equipment may seem advanced to you, but it is nothing compared to the tools we had available. Technology can be a massive game-changer.',
    general_25: 'These challenges have for sure been hard, but you are just at the beginning. If you can manage to reach zone 110 without ever equipping more than one item, you can have a small prize. Just to keep you motivated.',
    general_26: 'Congratulations, you definetely earned this one! At this point I can say with confidence that the Gooboo project has been nothing but successful so far!',
    general_27: 'Art, something I have not seen in a very long time. Not that I hate it, but there just hasn\'t been a need for physical items of decoration for a long time now.',
    general_28: 'Green, the colour of nature and life. In the old times, plants were a sign of a healthy ecosystem. As soon as the green went away, everything started going downhill.',
    general_29: 'Luckily, we don\'t have to worry about plants right now. We are a digital civilization, and our source of energy is not from a biological origin.',
    general_30: 'Back in the old times, we used to auction the most beautiful pieces of art for huge amounts of cash. Maybe, we can recreate this. Let\'s see how much cash you can get for this old vase.',
    general_31: 'That should be enough! The vase is yours, and it should be more useful to you than it is to me. Just place it next to your painting, that should help your creativity.',
    general_32: '',
    general_33: '',
    general_34: '',
    general_35: '',
    general_36: '',
    general_37: '',
    general_38: '',
    general_39: '',
    general_40: '',
  },
  author: {
    g: 'G',
    grobodal: 'Grobodal',
    orladee: 'Orladee',
    oppenschroe: 'Oppenschroe',
    bellux: 'Bellux',
    onoclua: 'Onoclua',
    omnisolix: 'Omnisolix'
  }
};
