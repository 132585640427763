export default {
  maxLevel: '{0} 最高等级',
  queueEmpty: '队列为空',
  nextLevels: '下一级别',
  bookNotFound: '通过达到某些升级的最高级别来找到此功能的书籍升级',
  bookNotFoundVillage: '通过达到某些升级或建筑物的最高级别来找到此功能的书籍升级',

  keyset: {
    default: {
      notFound: '未发现升级',
      nextRequirement: '下次升级解锁于 ',
      persistent: '此升级是永久性的，不会重置声望',
      bought: '已购买升级'
    },
    building: {
      notFound: '未找到任何建筑物',
      nextRequirement: '下一栋建筑解锁于 ',
      persistent: '该建筑具有持久性，不会因声望而重置',
      bought: '已建成的建筑物'
    }
  },

  build: '建造',
  subtype: {
    village_housing: '该建筑算作住房，可提高前 25 栋建筑的全局等级',
    village_workstation: '这座建筑算作一个工作站'
  },

  description: {
    mining_craftingStation: '制作站可以让你消耗矿石来提高你的镐的力量，这对于增加你的伤害至关重要',
    mining_compressor: '压缩机允许您增加每个槽的矿石量以获得更高的纯度',
    mining_depthDweller: '这可以让您获得矿山的声望选项',
    mining_smeltery: '这允许您熔炼矿石和稀土来制造金属锭',
    mining_enhancingStation: '这允许您在独特的镐修改器上花费金属锭',
    mining_emberForge: '灰烬可以提高声望并提高你的冶炼速度',
    mining_stickyJar: '使用树脂可以获得更好的制作效果，但需要很长时间才能生成',
    mining_premiumCraftingSlots: '将您的一个制作槽升级为高级，将 x1 以上的杂质减半并使纯度加倍',

    village_treasury: '税率导致您的劳动村民消费食物并缴纳税款作为回报',
    village_church: '这使您可以享受村庄的声望选项',
    village_offeringPedestal: '这允许您牺牲资源来永久增加其容量',
    village_court: '可以启用政策来提供奖励以换取幸福，反之亦然',
    village_generator: '电力会增加你的资源增益，而污染会降低幸福感',
    village_darkCult: '宗教政策允许你优先考虑资源或信仰增益，而牺牲其他',
    village_pyramid: '随着时间的推移，探索者会发现新的资源。 雇佣一个需要600名村民，你可能需要移民政策来实现这一点!',
    village_radar: '扫描策略允许您优先考虑战利品增益或质量，而牺牲其他',

    horde_purifier: '随着时间的推移，腐化肉体会慢慢获得，可以让你减少腐化',

    farm_gardenGnome: '花园侏儒可以放置在田野上，有几率赚取新货币',
    farm_learning: '作物经验让你提升全局等级',
    farm_sprinkler: '喷水器使一排作物生长得更快',
    farm_lectern: '讲台在一栏内增加作物经验',
    farm_pinwheel: '风车可以增加你的稀有掉落几率',
    farm_flag: '旗帜根据位置增加作物产量',
    farm_premiumGardenGnome: '高级花园侏儒提供双倍的黄金几率',
    farm_premiumSprinkler: '高级洒水器使生长速度和过度生长奖励翻倍',
    farm_premiumLectern: '高级讲台提供五倍经验',
    farm_premiumPinwheel: '高级风车提供双倍的掉落几率奖励',
    farm_premiumFlag: '高级旗帜提供双倍作物增益奖励',

    gallery_recycling: '转换允许您创建新的颜色',
    gallery_redLuck: '您需要先提升运气才能在包裹中找到鼓',
    gallery_epiphany: '创意让您可以将有限的灵感花在独特的奖励上',
    gallery_auctionHouse: '这使您可以访问画廊的声望选项',
    gallery_paintDrumStorage: '这可以让你收集包裹，其中有时包含鼓',
  },

  // Mining upgrades
  mining_damageUp: '伤害 +',
  mining_scrapGainUp: '废料增益 +',
  mining_scrapCapacityUp: '废料容量 +',
  mining_aluminiumCache: '铝存储',
  mining_aluminiumHardening: '铝淬火',
  mining_craftingStation: '制作站',
  mining_forge: '锻造',
  mining_oreSlots: '矿石槽位',
  mining_compressor: '压缩机',
  mining_copperCache: '铜存储',
  mining_aluminiumTanks: '铝罐',
  mining_aluminiumAnvil: '铝砧',
  mining_hullbreaker: '破壳者',
  mining_copperTanks: '铜罐',
  mining_depthDweller: '深度居民',
  mining_aluminiumExpansion: '铝扩张',
  mining_refinery: '精炼厂',
  mining_copperExpansion: '铜扩张',
  mining_drillFuel: '钻机燃料',
  mining_graniteHardening: '花岗岩硬化',
  mining_oreShelf: '矿石架',
  mining_heatShield: '隔热板',
  mining_tinCache: '锡存储',
  mining_furnace: '熔炉',
  mining_smeltery: '冶炼厂',
  mining_bronzeCache: '青铜存储',
  mining_ironCache: '铁存储',
  mining_oreWashing: '矿石洗选',
  mining_ironExpansion: '铁扩张',
  mining_ironHardening: '铁淬火',
  mining_ironFilter: '铁过滤器',
  mining_masterForge: '大师锻造',
  mining_starForge: '星际锻造',
  mining_magnet: '磁铁',
  mining_enhancingStation: '增强站',
  mining_warehouse: '仓库',
  mining_corrosiveFumes: '腐蚀性烟雾',
  mining_smeltingSalt: '闻盐',
  mining_titaniumExpansion: '钛扩张',
  mining_emberForge: '余烬锻造',
  mining_stickyJar: '粘罐子',
  mining_titaniumCache: '钛存储',
  mining_giantForge: '巨型锻造',
  mining_gunpowder: '火药',
  mining_nitricAcid: '硝酸',
  mining_metalDetector: '金属探测器',
  mining_recycling: '回收',
  mining_scanning: '扫描',
  mining_largerSurface: '更大的面积',
  mining_titaniumForge: '钛锻造',
  mining_platinumExpansion: '铂扩张',
  mining_platinumCache: '钛存储',
  mining_colossalOreStorage: '巨大的矿石储存',
  mining_titaniumBombs: '钛炸弹',
  mining_undergroundRadar: '地下雷达',
  mining_iridiumExpansion: '铱扩张',
  mining_iridiumCache: '铱存储',
  mining_iridiumTreetap: '铱树梢',
  mining_iridiumBombs: '铱炸弹',

  mining_fumes: '烟雾',
  mining_giantCrate: '巨型陨石坑',
  mining_morePressure: '更大的压力',
  mining_gasDweller: '燃气居民',
  mining_piston: '活塞',
  mining_pollution: '污染',
  mining_particleFilter: '颗粒过滤器',
  mining_hotAirBalloon: '热气球',
  mining_vent: '通风口',
  mining_harvester: '收割机',
  mining_graphiteRod: '石墨棒',
  mining_enrichedCrystal: '浓缩水晶',
  mining_smoker: '吸烟者',

  // Mining prestige upgrades
  mining_crystalBasics: '水晶基础',
  mining_crystalTips: '水晶提示',
  mining_crystalStorage: '水晶存储',
  mining_crystalDrill: '水晶钻',
  mining_crystalLens: '水晶透镜',
  mining_crystalAluminiumStorage: '水晶铝存储',
  mining_crystalCopperStorage: '水晶铜存储',
  mining_crystalTinStorage: '水晶锡存储',
  mining_crystalIronStorage: '水晶铁存储',
  mining_crystalTitaniumStorage: '水晶钛存储',
  mining_crystalPlatinumStorage: '水晶铂存储',
  mining_crystalIridiumStorage: '水晶铱存储',
  mining_crystalDetector: '水晶检测器',
  mining_crystalPreservarium: '水晶保藏室',
  mining_crystalTools: '水晶工具',
  mining_crystalExplosives: '水晶炸药',
  mining_crystalRefinery: '水晶精炼厂',
  mining_crystalSmeltery: '水晶冶炼厂',
  mining_crystalEnhancer: '水晶强化',
  mining_crystalTreetap: '水晶树梢',
  mining_crystalSalt: '水晶盐',
  mining_crystalBottle: '水晶瓶',
  mining_crystalEngine: '水晶引擎',
  mining_crystalCoal: '水晶煤',
  mining_crystalTruck: '水晶卡车',
  mining_crystalExpansion: '水晶扩张',

  mining_crystalSpikes: '水晶尖刺',
  mining_crystalBooster: '水晶助推器',
  mining_heliumReserves: '氦储备',
  mining_crystalSmoke: '水晶烟雾',
  mining_neonReserves: '氖储备',
  mining_crystalFusion: '水晶融合',
  mining_crystalRefuge: '水晶避难所',
  mining_argonReserves: '氩储备',
  mining_crystalTunnel: '水晶隧道',
  mining_crystalDust: '水晶尘',
  mining_kryptonReserves: '氪储备',

  // Mining premium upgrades
  mining_moreDamage: '更多伤害',
  mining_moreScrap: '更多废料',
  mining_moreAluminium: '更多铝',
  mining_moreCopper: '更多铜',
  mining_moreTin: '更多锡',
  mining_moreIron: '更多铁',
  mining_moreTitanium: '更多钛',
  mining_morePlatinum: '更多铂',
  mining_moreIridium: '更多铱',
  mining_premiumCraftingSlots: '高级制作槽',
  mining_moreGreenCrystal: '更多绿水晶',
  mining_moreRareEarth: '更多稀土',
  mining_fasterSmeltery: '更快的冶炼速度',
  mining_moreResin: '更多树脂',
  mining_moreHelium: '更多氦',
  mining_moreSmoke: '更多烟雾',
  mining_moreNeon: '更多氖',
  mining_moreArgon: '更多氩',

  // Mining books
  mining_bookAluminiumHardening: '书籍: 铝淬火',
  mining_bookAluminiumTanks: '书籍: 铝罐',
  mining_bookRefinery: '书籍: 炼油厂',
  mining_bookFurnace: '书籍: 熔炉',
  mining_bookIronExpansion: '书籍: 铁扩张',
  mining_bookMagnet: '书籍: 磁铁',
  mining_bookMetalDetector: '书籍: 金属探测器',

  // Village buildings
  village_campfire: '营火',
  village_hut: '棚子',
  village_storage: '存储',
  village_farm: '农场',
  village_plantation: '种植园',
  village_mine: '矿井',
  village_communityCenter: '社区中心',
  village_treasury: '财政部',
  village_crane: '起重机',
  village_forge: '锻造',
  village_smallHouse: '小房子',
  village_safe: '保险箱',
  village_well: '水井',
  village_garden: '花园',
  village_townHall: '市政厅',
  village_house: '房子',
  village_shed: '工棚',
  village_tunnel: '隧道',
  village_sawmill: '锯木厂',
  village_library: '图书馆',
  village_aquarium: '水族馆',
  village_glassBlowery: '玻璃吹制厂',
  village_church: '教堂',
  village_knowledgeTower: '知识塔',
  village_miniatureSmith: '微型铁匠',
  village_school: '学校',
  village_localGovernment: '地方政府',
  village_apartment: '公寓',
  village_temple: '寺庙',
  village_obelisk: '方尖碑',
  village_offeringPedestal: '供奉底座',
  village_theater: '剧院',
  village_lumberjackHut: '伐木工小屋',
  village_deepMine: '深矿井',
  village_bigStorage: '大存储空间',
  village_luxuryHouse: '豪宅',
  village_lake: '湖泊',
  village_gemSawBlade: '宝石锯片',
  village_miniatureGlassblowery: '微型冰淇淋店',
  village_lostPages: '丢失的页面',
  village_playground: '操场',
  village_government: '政府',
  village_modernHouse: '现代住宅',
  village_fountain: '喷泉',
  village_laboratory: '实验室',
  village_court: '法庭',
  village_greenhouse: '温室',
  village_fullBasket: '满篮',
  village_storageHall: '储藏室',
  village_bioLab: '生物实验室',
  village_festival: '节日',
  village_taxOffice: '税务局',
  village_cemetery: '墓地',
  village_mosque: '清真寺',
  village_waterTower: '水塔',
  village_outdoorPump: '室外泵',
  village_bankVault: '银行金库',
  village_steamEngine: '蒸汽机',
  village_mansion: '高级公寓',
  village_oilRig: '石油钻机',
  village_generator: '发电机',
  village_lighthouse: '灯塔',
  village_lobby: '大堂',
  village_oilStorage: '油存储',
  village_artGallery: '美术馆',
  village_excavator: '挖掘机',
  village_oilTruck: '油车',
  village_oldLibrary: '老图书馆',
  village_immigrationOffice: '移民局',
  village_marbleStatue: '大理石雕像',
  village_darkCult: '黑暗教派',
  village_slaughterhouse: '屠宰场',
  village_ecoCouncil: '生态委员会',
  village_treehouse: '树屋',
  village_rainforest: '雨林',
  village_luxuryStorage: '豪华储物空间',
  village_pyramid: '金字塔',
  village_trophyCase: '奖杯柜',
  village_antiquarian: '古玩',
  village_windTurbine: '风力发电机',
  village_radar: '雷达',
  village_waterTurbine: '水力涡轮',
  village_solarPanel: '太阳能板',

  // Village upgrades
  village_wallet: '钱包',
  village_resourceBag: '资源包',
  village_metalBag: '金属包',
  village_scythe: '镰刀',
  village_hatchet: '柴刀',
  village_pickaxe: '镐子',
  village_wateringCan: '喷壶',
  village_investment: '投资',
  village_basics: '基本',
  village_processing: '加工',
  village_pump: '水泵',
  village_sand: '沙子',
  village_book: '书籍',
  village_axe: '斧子',
  village_bomb: '炸弹',
  village_toll: '收费',
  village_fishingRod: '鱼竿',
  village_holyBook: '圣书',
  village_breakthrough: '突破',
  village_modifiedPlants: '改良植物',
  village_dopamine: '多巴胺',
  village_adrenaline: '肾上腺素',
  village_sprinkler: '洒水装置',
  village_greed: '贪婪',
  village_ambition: '野心',
  village_understanding: '理解',
  village_curiosity: '好奇心',
  village_worship: '崇拜',
  village_bartering: '物物交换',
  village_sparks: '火花',

  // Village prestige upgrades
  village_arch: '方舟',
  village_holyTree: '圣树',
  village_holyGrass: '圣草',
  village_holyRock: '圣石',
  village_holyMetal: '神圣金属',
  village_churchTax: '教会税',
  village_holyWater: '圣水',
  village_holyGlass: '神圣玻璃',
  village_holyCrane: '圣鹤',
  village_monk: '僧侣',
  village_holyPiggyBank: '神圣的存钱罐',
  village_deepWorship: '深深的崇拜',
  village_cityPlanning: '城市规划',
  village_managers: '经理人',
  village_warehouse: '仓库',
  village_sandstone: '砂岩',
  village_holyForest: '神圣森林',
  village_holyGem: '神圣宝石',
  village_deeperWorship: '更深层次的敬拜',
  village_holyLab: '神圣实验室',
  village_charity: '慈善机构',
  village_holyOil: '圣油',
  village_holyMarble: '神圣大理石',
  village_calmingSpeech: '平静的演讲',
  village_holyLoot: '神圣战利品',
  village_holyChisel: '神圣凿子',

  // Village premium upgrades
  village_overtime: '加时赛',
  village_goldenThrone: '黄金王座',
  village_fasterBuilding: '更快的建造',
  village_moreFaith: '更多信仰',
  village_moreWood: '更多木头',
  village_morePlantFiber: '更多植物纤维',
  village_moreStone: '更多石头',
  village_moreMetal: '更多金属',
  village_moreWater: '更多水',
  village_moreGlass: '更多玻璃',
  village_moreHardwood: '更多硬木',
  village_moreGem: '更多宝石',
  village_moreKnowledge: '更多知识',
  village_moreScience: '更多科学',
  village_moreOil: '更多油',
  village_moreMarble: '更多大理石',

  // Village books
  village_bookWallet: '书籍: 钱包',
  village_bookResourceBag: '书籍: 资源袋',
  village_bookMetalBag: '书籍: 金属袋',
  village_bookTreasury: '书籍: 财政部',
  village_bookStorage: '书籍: 存储',
  village_bookShed: '书籍: 工棚',
  village_bookSchool: '书籍: 学校',
  village_bookBigStorage: '书籍: 大存储空间',

  // Horde upgrades
  horde_attack: '攻击 +',
  horde_health: '生命值 +',
  horde_bones: '骨头 +',
  horde_training: '训练',
  horde_resilience: '恢复力',
  horde_boneBag: '骨袋',
  horde_rest: '休息',
  horde_anger: '愤怒',
  horde_monsterBag: '怪物袋',
  horde_thickSkin: '厚脸皮',
  horde_luckyStrike: '幸运一击',
  horde_monsterSoup: '怪物汤',
  horde_looting: '掠夺',
  horde_hoarding: '囤积',
  horde_stabbingGuide: '刺击指南',
  horde_dodgingGuide: '闪避指南',
  horde_plunderSecret: '掠夺秘密',
  horde_survivalGuide: '生存指南',
  horde_purifier: '净化器',
  horde_cleansingRitual: '净化仪式',
  horde_carving: '雕刻',
  horde_whitePaint: '白漆',
  horde_grossBag: '毛袋',
  horde_targetDummy: '目标假人',
  horde_milestone: '里程碑',
  horde_mysticalBag: '神秘袋',
  horde_collector: '收集器',

  // Horde prestige upgrades
  horde_balance: '平衡',
  horde_wrath: '愤怒',
  horde_peace: '和平',
  horde_milk: '牛奶',
  horde_butcher: '屠夫',
  horde_beginnerLuck: '新手运气',
  horde_advancedLuck: '进阶运气',
  horde_boneTrader: '骨头商人',
  horde_soulCage: '灵魂牢笼',
  horde_offenseBook: '进攻之书',
  horde_defenseBook: '防御之书',
  horde_candleCircle: '蜡烛圈',
  horde_ashCircle: '灰圈',
  horde_containmentChamber: '收容室',
  horde_mausoleum: '陵墓',
  horde_lastWill: '遗愿',
  horde_combatStudies: '战斗研究',
  horde_boneChamber: '骨室',

  // Horde crown upgrades
  horde_royalSword: '皇家宝剑',
  horde_royalArmor: '皇家盔甲',
  horde_royalStorage: '皇家存储',
  horde_royalButcher: '皇家屠夫',
  horde_royalCrypt: '皇家墓穴',

  // Horde premium upgrades
  horde_morePower: '更多力量',
  horde_moreBones: '更多骨头',
  horde_moreMonsterParts: '更多怪物零件',
  horde_moreSouls: '更多灵魂',
  horde_moreMastery: '更多精通',
  horde_ancientPower: '古老的力量',
  horde_ancientFortitude: '古老的坚韧',
  horde_ancientWealth: '古老的财富',
  horde_ancientSpirit: '古老的精神',
  horde_ancientSharpsight: '古老的锐视',
  horde_ancientHolding: '古老的保持',
  horde_ancientReaping: '古老的收割',
  horde_ancientRemembrance: '古老的回忆',
  horde_ancientExpertise: '古老的经验',
  horde_ancientMystery: '古老的精通',

  // Horde books
  horde_bookTraining: '书籍: 训练',
  horde_bookLuckyStrike: '书籍: 幸运一击',
  horde_bookLooting: '书籍: 掠夺',
  horde_bookSurvivalGuide: '书籍: 生存指南',
  horde_bookCarving: '书籍: 雕刻',
  horde_bookWhitePaint: '书籍: 白漆',

  // Farm upgrades
  farm_seedBox: '种子箱',
  farm_fertility: '繁殖力',
  farm_expansion: '扩张',
  farm_gardenGnome: '花园侏儒',
  farm_overgrowth: '过度生长',
  farm_learning: '学习',
  farm_manure: '肥料',
  farm_groundSeeds: '磨碎种子',
  farm_roastedSeeds: '烤种子',
  farm_hayBales: 'Hay bales',
  farm_smallCrate: '小箱子',
  farm_sprinkler: '洒水装置',
  farm_scarecrow: '稻草人',
  farm_magnifyingGlass: '放大镜',
  farm_bugPowder: '虫粉',
  farm_shed: '棚子',
  farm_lectern: '讲台',
  farm_perfume: '香水',
  farm_mediumCrate: '中箱子',
  farm_stompedSeeds: '踩碎种子',
  farm_insectParadise: '昆虫天堂',
  farm_goldenTools: '黄金工具',
  farm_butterflyWings: '蝴蝶翅膀',
  farm_fertileGround: '肥沃之地',
  farm_pinwheel: '风车',
  farm_mysticGround: '神秘之地',
  farm_fertilizerBag: '化肥袋',
  farm_bigCrate: '大箱子',
  farm_artificialWebs: '人造网',
  farm_studyInsects: '研究昆虫',
  farm_beehive: '蜂巢',
  farm_flag: '旗帜',

  // Farm premium upgrades
  farm_biggerVegetables: '更大的蔬菜',
  farm_biggerFruits: '更大的水果',
  farm_biggerGrain: '更大的粮食',
  farm_biggerFlowers: '更大的鲜花',
  farm_moreExperience: '更多经验',
  farm_premiumGardenGnome: '高级花园侏儒',
  farm_premiumSprinkler: '高级洒水装置',
  farm_premiumLectern: '高级讲台',
  farm_premiumPinwheel: '高级风车',
  farm_premiumFlag: '高级旗帜',

  // Farm books
  farm_bookSmallCrate: '书籍: 小箱子',
  farm_bookScarecrow: '书籍: 稻草人',
  farm_bookShed: '书籍: 棚子',
  farm_bookMediumCrate: '书籍: 中箱子',
  farm_bookInsectParadise: '书籍: 昆虫天堂',
  farm_bookBigCrate: '书籍: 大箱子',

  // Gallery upgrades
  gallery_newStyle: '新风格',
  gallery_recycling: '循环利用',
  gallery_redPower: '红色力量',
  gallery_redConversion: '红色转换',
  gallery_filters: '过滤器',
  gallery_orangePower: '橙色力量',
  gallery_redLuck: '红色幸运',
  gallery_epiphany: '顿悟',
  gallery_trashCan: '垃圾箱',
  gallery_orangeConversion: '橙色转换',
  gallery_brush: '画笔',
  gallery_auctionHouse: '拍卖行',
  gallery_yellowPower: '黄色力量',
  gallery_orangeLuck: '橙色幸运',
  gallery_yellowConversion: '黄色转换',
  gallery_paintDrumStorage: '油漆桶储存',
  gallery_greenPower: '绿色力量',
  gallery_yellowLuck: '黄色幸运',
  gallery_greenConversion: '绿色转换',
  gallery_redRage: '红色愤怒',
  gallery_bluePower: '蓝色力量',
  gallery_greenLuck: '绿色幸运',
  gallery_blueConversion: '蓝色转换',
  gallery_purplePower: '紫色力量',
  gallery_blueLuck: '蓝色幸运',
  gallery_purpleConversion: '紫色转换',

  // Gallery prestige upgrades
  gallery_artAcademy: '艺术学院',
  gallery_redCrayon: '红色蜡笔',
  gallery_rainbowJar: '彩虹罐',
  gallery_trashContainer: '垃圾箱',
  gallery_orangeCrayon: '橙色蜡笔',
  gallery_headstart: '抢先一步',
  gallery_forklift: '叉车',
  gallery_redCrate: '红色箱子',
  gallery_yellowCrayon: '黄色蜡笔',
  gallery_inspiringBooks: '励志书籍',
  gallery_expressDelivery: '快递',
  gallery_orangeCrate: '橙色箱子',
  gallery_greenCrayon: '绿色蜡笔',
  gallery_sortingSystem: '分拣系统',
  gallery_redTruck: '红色卡车',
  gallery_yellowCrate: '黄色箱子',
  gallery_blueCrayon: '蓝色蜡笔',
  gallery_orangeTruck: '橙色卡车',
  gallery_greenCrate: '绿色箱子',
  gallery_purpleCrayon: '紫色蜡笔',

  // Gallery premium upgrades
  gallery_prettyColors: '漂亮的颜色',
  gallery_prettyConverter: '漂亮的转换器',
  gallery_prettyCash: '漂亮的零钱',
  gallery_prettyRed: '漂亮的红色',
  gallery_prettyOrange: '漂亮的橙色',
  gallery_prettyYellow: '漂亮的黄色',
  gallery_prettyGreen: '漂亮的绿色',
  gallery_prettyBlue: '漂亮的蓝色',

  // Gallery books
  gallery_bookRedPower: '书籍: 红色力量',
  gallery_bookOrangePower: '书籍: 橙色力量',
  gallery_bookYellowPower: '书籍: 黄色力量',
  gallery_bookGreenPower: '书籍: 绿色力量',
  gallery_bookBluePower: '书籍: 蓝色力量',
  gallery_bookPurplePower: '书籍: 紫色力量',

  // Gem premium upgrades
  gem_topazBag: '黄玉袋',

  // School premium upgrades
  school_student: '学生',

  // Treasure premium upgrades
  treasure_moreSlots: '更多插槽',
  treasure_moreFragments: '更多片段',

  // Event upgrades
  event_firefly: '萤火虫',
  event_glowshroom: '发光蘑菇',
  event_glowfish: '发光鱼',
  event_lantern: '灯',
  event_campfire: '营火',
  event_coral: '珊瑚',
  event_jellyfish: '海蜇',
  event_nightbloom: '夜花',
  event_neonlight: '霓虹灯',
  event_sun: '太阳',
  event_moonglow: '月光',
  event_burningFly: '燃烧的苍蝇',
  event_moreSpores: '更多孢子',
  event_fins: '翅片',
  event_lamppost: '灯柱',
  event_campfireStories: '篝火故事',
  event_sponge: '海绵',
  event_longerTentacles: '更长的触手',
  event_nightTime: '夜间',
  event_city: '城市',
  event_moreHelium: '更多氦',
  event_furiousFly: '狂暴的苍蝇',
  event_mycelium: '菌丝',
  event_gills: '鳃',
  event_dimmer: '调光器',
  event_marshmallows: '棉花糖',
  event_anemone: '海葵',
  event_toxin: '毒素',
  event_fullMoon: '满月',
  event_ads: '广告',
  event_lessDistance: '距离更短',
  event_fireflyEnlightened: '明亮的萤火虫',
  event_glowshroomEnlightened: '明亮的发光蘑菇',
  event_glowfishEnlightened: '明亮的发光鱼',
  event_lanternEnlightened: '明亮的灯',
  event_campfireEnlightened: '明亮的营火',
  event_coralEnlightened: '明亮的珊瑚',
  event_jellyfishEnlightened: '明亮的海蜇',
  event_nightbloomEnlightened: '明亮的夜花',
  event_neonlightEnlightened: '明亮的霓虹灯',
  event_sunEnlightened: '明亮的阳光',

  event_colorfulSeedBag: '多彩种子袋',
  event_flowerPot: '花盆',
  event_daisyProtection: '雏菊防护',
  event_poppyProtection: '罂粟保护',
  event_poppyFertilizer: '罂粟肥料',
  event_irisProtection: '鸢尾花保护',
  event_irisFertilizer: '鸢尾花肥料',
  event_lilyProtection: '百合保护',
  event_lilyFertilizer: '百合肥料',
  event_orchidProtection: '兰花保护',
  event_orchidFertilizer: '兰花肥料',
  event_cornflowerProtection: '矢车菊保护',
  event_cornflowerFertilizer: '矢车菊肥料',
  event_greenhouse: '温室',
  event_hugeVase: '巨型花瓶',
  event_framedDaisy: '镶框雏菊',
  event_framedPoppy: '镶框罂粟',
  event_framedIris: '镶框鸢尾花',
  event_framedLily: '镶框百合',
  event_framedOrchid: '镶框兰花',
  event_framedCornflower: '镶框矢车菊',

  event_juicyBait: '多汁的诱饵',
  event_incubator: '培养箱',
  event_fishWhistle: '鱼哨',
  event_pollution: '污染',
  event_goldenHook: '金钩',

  event_extraBuildingSlot: '额外的建筑槽位',
  event_doubleTime: '双倍时间',
  event_tropicalBlessing: '热带祝福',

  event_essenceCondenser: '精华冷凝器',
  event_luckyCharm: '幸运符',
  event_biggerCauldron: '更大的锅',
  event_potionShelf: '药水架子',
  event_ritualChalk: '仪式粉笔',
  event_stabilizer: '稳定器',
  event_pedestals: '底座',
  event_mystifier: '神秘者',
  event_bundle: '捆绑包',
  event_bagOfCandy: '一袋糖果',

  event_pineTrees: '松树',
  event_woolHat: '羊毛帽',
  event_mulledWine: '热红酒',
  event_cookies: '饼干',
  event_iceSculpture: '冰雕',
  event_attackBoost: '攻击提升',
  event_healthBoost: '生命值提升',
  event_lootBoost: '战利品提升',
};
