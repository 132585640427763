export default {
  maxLevel: '{0}-Maximalstufe',
  queueEmpty: 'Warteschlange leer',
  nextLevels: 'Nächste Stufen',
  bookNotFound: 'Finde Buch-Verbesserungen für diese Funktion, indem du auf bestimmten Verbesserungen die höchste Stufe erreichst',
  bookNotFoundVillage: 'Finde Buch-Verbesserungen für diese Funktion, indem du auf bestimmten Verbesserungen oder Gebäuden die höchste Stufe erreichst',

  keyset: {
    default: {
      notFound: 'Keine Verbesserungen gefunden',
      nextRequirement: 'Nächste Verbesserung bei ',
      persistent: 'Diese Verbesserung ist dauerhaft und wird nicht durch Prestige zurückgesetzt',
      bought: 'Verbesserungen gekauft'
    },
    building: {
      notFound: 'Keine Gebäude gefunden',
      nextRequirement: 'Nächstes Gebäude bei ',
      persistent: 'Dieses Gebäude ist dauerhaft und wird nicht durch Prestige zurückgesetzt',
      bought: 'Gebäude errichtet'
    }
  },

  build: 'Bauen',
  subtype: {
    village_housing: 'Dieses Gebäude zählt als Wohnraum und erhöht die globale Stufe für die ersten 25, die gebaut werden',
    village_workstation: 'Dieses Gebäude zählt als Arbeitsplatz'
  },

  description: {
    mining_craftingStation: 'Die Werkbank erlaubt es dir, Orze zu verbrauchen, um deine Spitzhackenkraft zu erhöhen. Sie ist essentiell, um deinen Schaden zu steigern',
    mining_compressor: 'Mit dem Kompressor kannst du die Erzmenge pro Herstellplatz erhöhen, um die Reinheit der Spitzhacke zu verbessern',
    mining_depthDweller: 'Dies gewährt dir Zugang zur Prestige-Möglichkeit vom Bergbau',
    mining_smeltery: 'Hiermit kannst du Erze und Bodenschätze zu Barren schmelzen',
    mining_enhancingStation: 'Dies erlaubt es dir, Barren zu benutzen, um einzigartige Spitzhackeneffekte zu erhalten',
    mining_emberForge: 'Glut wird beim Prestige erhalten und erhöht das Tempo vom Hochofen',
    mining_stickyJar: 'Harz verbessert die Ergebnisse von hergestellten Spitzhacken, braucht aber lange zum Erzeugen',
    mining_premiumCraftingSlots: 'Wertet einen deiner Herstellplätze zur Premium-Qualität auf, wodurch Unreinheit über x1 halbiert und Reinheit verdoppelt wird',

    village_treasury: 'Der Steuersatz sorgt dafür, dass arbeitende Dorfbewohner Nahrung verbrauchen und im Gegenzug Steuern zahlen',
    village_church: 'Dies gewährt dir Zugang zur Prestige-Möglichkeit vom Dorf',
    village_offeringPedestal: 'Dies erlaubt es dir, Ressourcen zu opfern, um ihre Kapazität dauerhaft zu erhöhen',
    village_court: 'Verordnungen können aktiviert werden, um Boni im Austausch für Zufriedenheit zu erhalten, oder umgekehrt',
    village_generator: 'Energie erhöht dein Rohstoffeinkommen und Verschmutzung senkt die Zufriedenheit',
    village_darkCult: 'Die Religionsverordnung erlaubt es dir, Rohstoffe oder Glaube zu priorisieren, auf Kosten des Anderen',
    village_pyramid: 'Entdecker finden einen neuen Rohstoff mit der Zeit. Die Einstellung benötigt 600 Dorfbewohner, vielleich brauchst du hierfür die Einwanderungsverordnung!',
    village_radar: 'Die Scanverordnung erlaubt es dir, Beuteeinkommen oder Beutequalität zu priorisieren, auf Kosten des Anderen',

    horde_purifier: 'Erhalte langsam korruptes Fleisch und nutze es, um die Korruption zu senken',

    farm_gardenGnome: 'Gartenzwerge können auf das Feld gesetzt werden, um eine Chance auf eine neue Währung zu erhalten',
    farm_learning: 'Mit Pflanzenerfahrung kann die globale Stufe erhöht werden',
    farm_sprinkler: 'Rasensprenger lassen Pflanzen in einer Zeile schneller wachsen',
    farm_lectern: 'Rednerpulte erhöhen die Pflanzenerfahrung in einer Spalte',
    farm_pinwheel: 'Windräder erlauben es dir, mehr seltenen Ertrag zu finden',
    farm_flag: 'Flaggen erhöhen den Ertrag abhängig von der Position',
    farm_premiumGardenGnome: 'Premium-Gartenzwerge bieten die doppelte Goldchance',
    farm_premiumSprinkler: 'Premium-Rasensprenger verdoppeln den Bonus auf Wachstum und Überwuchern',
    farm_premiumLectern: 'Premium-Rednerpulte verfünffachen die Erfahrung',
    farm_premiumPinwheel: 'Premium-Windräder bieten den doppelten Bonus auf seltenen Ertrag',
    farm_premiumFlag: 'Premium-Flaggen bieten den doppelten Ertragsbonus',

    gallery_recycling: 'Durch Konversion können neue Farben erzeugt werden',
    gallery_redLuck: 'Du brauchst Glücksverbesserungen, bevor du Farbtrommeln in Paketen finden kannst',
    gallery_epiphany: 'Durch Ideen kannst du begrenzte Inspiration für einzigartige Boni nutzen',
    gallery_auctionHouse: 'Dies gewährt dir Zugang zur Prestige-Möglichkeit von der Gallerie',
    gallery_paintDrumStorage: 'Dies erlaubt das Sammeln von Paketen, welche manchmal Farbtrommeln enthalten',
  },

  // Mining upgrades
  mining_damageUp: 'Schaden +',
  mining_scrapGainUp: 'Schrott-Einkommen +',
  mining_scrapCapacityUp: 'Schrott-Kapazität +',
  mining_aluminiumCache: 'Aluminiumspeicher',
  mining_aluminiumHardening: 'Aluminium härten',
  mining_craftingStation: 'Werkbank',
  mining_forge: 'Schmied',
  mining_oreSlots: 'Erzplätze',
  mining_compressor: 'Kompressor',
  mining_copperCache: 'Kupferspeicher',
  mining_aluminiumTanks: 'Aluminiumtanks',
  mining_aluminiumAnvil: 'Aluminiumamboss',
  mining_hullbreaker: 'Hüllenbrecher',
  mining_copperTanks: 'Kupfertanks',
  mining_depthDweller: 'Tiefengräber',
  mining_aluminiumExpansion: 'Aluminiumexpansion',
  mining_refinery: 'Raffinerie',
  mining_copperExpansion: 'Kupferexpansion',
  mining_drillFuel: 'Bohrertreibstoff',
  mining_graniteHardening: 'Granit härten',
  mining_oreShelf: 'Erzregal',
  mining_heatShield: 'Hitzeschild',
  mining_tinCache: 'Zinnspeicher',
  mining_furnace: 'Ofen',
  mining_smeltery: 'Hochofen',
  mining_bronzeCache: 'Bronzespeicher',
  mining_ironCache: 'Eisenspeicher',
  mining_oreWashing: 'Erzwaschen',
  mining_ironExpansion: 'Eisenexpansion',
  mining_ironHardening: 'Eisen härten',
  mining_ironFilter: 'Eisenfilter',
  mining_masterForge: 'Meisterschmied',
  mining_starForge: 'Sternenschmied',
  mining_magnet: 'Magnet',
  mining_enhancingStation: 'Aufwert-Station',
  mining_warehouse: 'Lagerhalle',
  mining_corrosiveFumes: 'Ätzende Dämpfe',
  mining_smeltingSalt: 'Schmelzsalz',
  mining_titaniumExpansion: 'Titanexpansion',
  mining_emberForge: 'Glutschmied',
  mining_stickyJar: 'Klebriges Gefäß',
  mining_titaniumCache: 'Titanspeicher',
  mining_giantForge: 'Riesenschmied',
  mining_gunpowder: 'Schwarzpulver',
  mining_nitricAcid: 'Salpetersäure',
  mining_metalDetector: 'Metalldetektor',
  mining_recycling: 'Recycling',
  mining_scanning: 'Scannen',
  mining_largerSurface: 'Größere Oberfläche',
  mining_titaniumForge: 'Titanschmied',
  mining_platinumExpansion: 'Platinexpansion',
  mining_platinumCache: 'Platinspeicher',
  mining_colossalOreStorage: 'Kolossaler Erzspeicher',
  mining_titaniumBombs: 'Titanbomben',
  mining_undergroundRadar: 'Unterirdisches Radar',
  mining_iridiumExpansion: 'Iridiumexpansion',
  mining_iridiumCache: 'Iridiumspeicher',
  mining_iridiumTreetap: 'Iridium-Zapfhahn',
  mining_iridiumBombs: 'Iridiumbomben',

  mining_fumes: 'Dämpfe',
  mining_giantCrate: 'Riesenkiste',
  mining_morePressure: 'Mehr Druck',
  mining_gasDweller: 'Gasgräber',
  mining_piston: 'Kolben',
  mining_pollution: 'Verschmutzung',
  mining_particleFilter: 'Partikelfilter',
  mining_hotAirBalloon: 'Heißluftballon',
  mining_vent: 'Lüfter',
  mining_harvester: 'Ernter',
  mining_graphiteRod: 'Graphitstab',
  mining_enrichedCrystal: 'Angereicherter Kristall',
  mining_smoker: 'Räucherofen',

  // Mining prestige upgrades
  mining_crystalBasics: 'Kristall-Grundlagen',
  mining_crystalTips: 'Kristall-Spitzen',
  mining_crystalStorage: 'Kristall-Speicher',
  mining_crystalDrill: 'Kristall-Bohrer',
  mining_crystalLens: 'Kristall-Linse',
  mining_crystalAluminiumStorage: 'Kristall-Aluminiumlager',
  mining_crystalCopperStorage: 'Kristall-Kupferlager',
  mining_crystalTinStorage: 'Kristall-Zinnlager',
  mining_crystalIronStorage: 'Kristall-Eisenlager',
  mining_crystalTitaniumStorage: 'Kristall-Titanlager',
  mining_crystalPlatinumStorage: 'Kristall-Platinlager',
  mining_crystalIridiumStorage: 'Kristall-Iridiumlager',
  mining_crystalDetector: 'Kristall-Detektor',
  mining_crystalPreservarium: 'Kristall-Reservat',
  mining_crystalTools: 'Kristall-Werkzeug',
  mining_crystalExplosives: 'Kristall-Sprengstoff',
  mining_crystalRefinery: 'Kristall-Raffinerie',
  mining_crystalSmeltery: 'Kristall-Hochofen',
  mining_crystalEnhancer: 'Kristall-Aufwerter',
  mining_crystalTreetap: 'Kristall-Zapfhahn',
  mining_crystalSalt: 'Kristall-Salz',
  mining_crystalBottle: 'Kristall-Flasche',
  mining_crystalEngine: 'Kristall-Motor',
  mining_crystalCoal: 'Kristall-Kohle',
  mining_crystalTruck: 'Kristall-LKW',
  mining_crystalExpansion: 'Kristall-Expansion',

  mining_crystalSpikes: 'Kristall-Stacheln',
  mining_crystalBooster: 'Kristall-Booster',
  mining_heliumReserves: 'Heliumreserven',
  mining_crystalSmoke: 'Kristall-Rauch',
  mining_neonReserves: 'Neonreserven',
  mining_crystalFusion: 'Kristall-Fusion',
  mining_crystalRefuge: 'Kristall-Zuflucht',
  mining_argonReserves: 'Argonreserven',
  mining_crystalTunnel: 'Kristall-Tunnel',
  mining_crystalDust: 'Kristall-Staub',
  mining_kryptonReserves: 'Kristall-Reserven',

  // Mining premium upgrades
  mining_moreDamage: 'Mehr Schaden',
  mining_moreScrap: 'Mehr Schrott',
  mining_moreAluminium: 'Mehr Aluminium',
  mining_moreCopper: 'Mehr Kupfer',
  mining_moreTin: 'Mehr Zinn',
  mining_moreIron: 'Mehr Eisen',
  mining_moreTitanium: 'Mehr Titan',
  mining_morePlatinum: 'Mehr Platin',
  mining_moreIridium: 'Mehr Iridium',
  mining_premiumCraftingSlots: 'Premium-Herstellplätze',
  mining_moreGreenCrystal: 'Mehr grüne Kristalle',
  mining_moreRareEarth: 'Mehr Bodenschätze',
  mining_fasterSmeltery: 'Schnellerer Hochofen',
  mining_moreResin: 'Mehr Harz',
  mining_moreHelium: 'Mehr Helium',
  mining_moreSmoke: 'Mehr Rauch',
  mining_moreNeon: 'Mehr Neon',
  mining_moreArgon: 'Mehr Argon',

  // Mining books
  mining_bookAluminiumHardening: 'Buch: Aluminium härten',
  mining_bookAluminiumTanks: 'Buch: Aluminiumtanks',
  mining_bookRefinery: 'Buch: Raffinerie',
  mining_bookFurnace: 'Buch: Ofen',
  mining_bookIronExpansion: 'Buch: Eisenexpansion',
  mining_bookMagnet: 'Buch: Magnet',
  mining_bookMetalDetector: 'Buch: Metalldetektor',

  // Village buildings
  village_campfire: 'Lagerfeuer',
  village_hut: 'Hütte',
  village_storage: 'Lager',
  village_farm: 'Bauernhof',
  village_plantation: 'Plantage',
  village_mine: 'Bergwerk',
  village_communityCenter: 'Gemeindehaus',
  village_treasury: 'Schatzkammer',
  village_crane: 'Kran',
  village_forge: 'Schmied',
  village_smallHouse: 'Kleines Haus',
  village_safe: 'Safe',
  village_well: 'Brunnen',
  village_garden: 'Garten',
  village_townHall: 'Rathaus',
  village_house: 'Haus',
  village_shed: 'Schuppen',
  village_tunnel: 'Tunnel',
  village_sawmill: 'Sägewerk',
  village_library: 'Bücherei',
  village_aquarium: 'Aquarium',
  village_glassBlowery: 'Glasbläserei',
  village_church: 'Kirche',
  village_knowledgeTower: 'Wissensturm',
  village_miniatureSmith: 'Miniaturschmied',
  village_school: 'Schule',
  village_localGovernment: 'Kommunalverwaltung',
  village_apartment: 'Apartment',
  village_temple: 'Tempel',
  village_obelisk: 'Obelisk',
  village_offeringPedestal: 'Opfertisch',
  village_theater: 'Theater',
  village_lumberjackHut: 'Holzfällerhütte',
  village_deepMine: 'Tiefer Stollen',
  village_bigStorage: 'Großes Lager',
  village_luxuryHouse: 'Luxushaus',
  village_lake: 'See',
  village_gemSawBlade: 'Edelsteinsäge',
  village_miniatureGlassblowery: 'Miniatur-Glasbläserei',
  village_lostPages: 'Verlorene Seiten',
  village_playground: 'Spielplatz',
  village_government: 'Regierung',
  village_modernHouse: 'Modernes Haus',
  village_fountain: 'Springbrunnen',
  village_laboratory: 'Labor',
  village_court: 'Gericht',
  village_greenhouse: 'Gewächshaus',
  village_fullBasket: 'Voller Korb',
  village_storageHall: 'Lagerhalle',
  village_bioLab: 'Bio-Labor',
  village_festival: 'Festival',
  village_taxOffice: 'Steuerbüro',
  village_cemetery: 'Friedhof',
  village_mosque: 'Moschee',
  village_waterTower: 'Wasserturm',
  village_outdoorPump: 'Außenpumpe',
  village_bankVault: 'Banktresor',
  village_steamEngine: 'Dampfmaschine',
  village_mansion: 'Villa',
  village_oilRig: 'Ölbohrer',
  village_generator: 'Generator',
  village_lighthouse: 'Leuchtturm',
  village_lobby: 'Lobby',
  village_oilStorage: 'Ölspeicher',
  village_artGallery: 'Kunstgallerie',
  village_excavator: 'Bagger',
  village_oilTruck: 'Öltanker',
  village_oldLibrary: 'Alte Bücherei',
  village_immigrationOffice: 'Einwanderungsbüro',
  village_marbleStatue: 'Marmorstatue',
  village_darkCult: 'Dunkler Kult',
  village_slaughterhouse: 'Schlachthaus',
  village_ecoCouncil: 'Ökorat',
  village_treehouse: 'Baumhaus',
  village_rainforest: 'Regenwald',
  village_luxuryStorage: 'Luxuslager',
  village_pyramid: 'Pyramide',
  village_trophyCase: 'Trophäenschrank',
  village_antiquarian: 'Antiquar',
  village_windTurbine: 'Windrad',
  village_radar: 'Radar',
  village_waterTurbine: 'Wasserkraftwerk',
  village_solarPanel: 'Solarzelle',

  // Village upgrades
  village_wallet: 'Geldbörse',
  village_resourceBag: 'Rohstoffbeutel',
  village_metalBag: 'Metallbeutel',
  village_scythe: 'Sense',
  village_hatchet: 'Beil',
  village_pickaxe: 'Spitzhacke',
  village_wateringCan: 'Gießkanne',
  village_investment: 'Investition',
  village_basics: 'Grundlagen',
  village_processing: 'Verarbeitung',
  village_pump: 'Pumpwerk',
  village_sand: 'Sand',
  village_book: 'Buch',
  village_axe: 'Axt',
  village_bomb: 'Bombe',
  village_toll: 'Zoll',
  village_fishingRod: 'Angel',
  village_holyBook: 'Heiliges Buch',
  village_breakthrough: 'Durchbruch',
  village_modifiedPlants: 'Modifizierte Pflanzen',
  village_dopamine: 'Dopamin',
  village_adrenaline: 'Adrenalin',
  village_sprinkler: 'Rasensprenger',
  village_greed: 'Gier',
  village_ambition: 'Ambition',
  village_understanding: 'Verständnis',
  village_curiosity: 'Neugier',
  village_worship: 'Verehrung',
  village_bartering: 'Feilschen',
  village_sparks: 'Funken',

  // Village prestige upgrades
  village_arch: 'Arche',
  village_holyTree: 'Heiliger Baum',
  village_holyGrass: 'Heiliges Gras',
  village_holyRock: 'Heiliger Fels',
  village_holyMetal: 'Heiliges Metall',
  village_churchTax: 'Kirchensteuer',
  village_holyWater: 'Weihwasser',
  village_holyGlass: 'Heiliges Glas',
  village_holyCrane: 'Heiliger Kran',
  village_monk: 'Mönch',
  village_holyPiggyBank: 'Heiliges Sparschwein',
  village_deepWorship: 'Tiefer Glaube',
  village_cityPlanning: 'Stadtplanung',
  village_managers: 'Manager',
  village_warehouse: 'Lagerhalle',
  village_sandstone: 'Sandstein',
  village_holyForest: 'Heiliger Wald',
  village_holyGem: 'Heiliger Edelstein',
  village_deeperWorship: 'Tieferer Glaube',
  village_holyLab: 'Heiliges Labor',
  village_charity: 'Wohltäter',
  village_holyOil: 'Heiliges Öl',
  village_holyMarble: 'Heiliger Marmor',
  village_calmingSpeech: 'Beruhigende Rede',
  village_holyLoot: 'Heilige Beute',
  village_holyChisel: 'Heiliger Meißel',

  // Village premium upgrades
  village_overtime: 'Überstunden',
  village_goldenThrone: 'Goldener Thron',
  village_fasterBuilding: 'Schnelleres Bauen',
  village_moreFaith: 'Mehr Glaube',
  village_moreWood: 'Mehr Holz',
  village_morePlantFiber: 'Mehr Pflanzenfasern',
  village_moreStone: 'Mehr Stein',
  village_moreMetal: 'Mehr Metall',
  village_moreWater: 'Mehr Wasser',
  village_moreGlass: 'Mehr Glas',
  village_moreHardwood: 'Mehr Hartholz',
  village_moreGem: 'Mehr Edelsteine',
  village_moreKnowledge: 'Mehr Wissen',
  village_moreScience: 'Mehr Wissenschaft',
  village_moreOil: 'Mehr Öl',
  village_moreMarble: 'Mehr Marmor',

  // Village books
  village_bookWallet: 'Buch: Geldbörse',
  village_bookResourceBag: 'Buch: Rohstoffbeutel',
  village_bookMetalBag: 'Buch: Metallbeutel',
  village_bookTreasury: 'Buch: Schatzkammer',
  village_bookStorage: 'Buch: Lager',
  village_bookShed: 'Buch: Schuppen',
  village_bookSchool: 'Buch: Schule',
  village_bookBigStorage: 'Buch: Großes Lager',

  // Horde upgrades
  horde_attack: 'Angriff +',
  horde_health: 'Leben +',
  horde_bones: 'Knochen +',
  horde_training: 'Training',
  horde_resilience: 'Widerstandsfähigkeit',
  horde_boneBag: 'Knochenbeutel',
  horde_rest: 'Ruhe',
  horde_anger: 'Wut',
  horde_monsterBag: 'Monsterbeutel',
  horde_thickSkin: 'Dicke Haut',
  horde_luckyStrike: 'Glückstreffer',
  horde_monsterSoup: 'Monstersuppe',
  horde_looting: 'Plündern',
  horde_hoarding: 'Horten',
  horde_stabbingGuide: 'Stech-Anleitung',
  horde_dodgingGuide: 'Ausweich-Anleitung',
  horde_plunderSecret: 'Plündergeheimnis',
  horde_survivalGuide: 'Überlebens-Anleitung',
  horde_purifier: 'Reiniger',
  horde_cleansingRitual: 'Säuberndes Ritual',
  horde_carving: 'Schnitzen',
  horde_whitePaint: 'Weiße Farbe',
  horde_grossBag: 'Ekelbeutel',
  horde_targetDummy: 'Trainingspuppe',
  horde_milestone: 'Meilenstein',
  horde_mysticalBag: 'Mystischer Beutel',
  horde_collector: 'Sammler',

  // Horde prestige upgrades
  horde_balance: 'Balance',
  horde_wrath: 'Zorn',
  horde_peace: 'Frieden',
  horde_milk: 'Milch',
  horde_butcher: 'Schlachter',
  horde_beginnerLuck: 'Anfängerglück',
  horde_advancedLuck: 'Fortgeschrittenenglück',
  horde_boneTrader: 'Knochenhändler',
  horde_soulCage: 'Seelenkäfig',
  horde_offenseBook: 'Angriffsbuch',
  horde_defenseBook: 'Verteidigungsbuch',
  horde_candleCircle: 'Kerzenkreis',
  horde_ashCircle: 'Aschekreis',
  horde_containmentChamber: 'Isolationskammer',
  horde_mausoleum: 'Mausoleum',
  horde_lastWill: 'Letzter Wille',
  horde_combatStudies: 'Kampf-Studien',
  horde_boneChamber: 'Knochenkammer',

  // Horde crown upgrades
  horde_royalSword: 'Königliches Schwert',
  horde_royalArmor: 'Königliche Rüstung',
  horde_royalStorage: 'Königliches Lager',
  horde_royalButcher: 'Königlicher Schlachter',
  horde_royalCrypt: 'Königliches Grab',

  // Horde premium upgrades
  horde_morePower: 'Mehr Kampfkraft',
  horde_moreBones: 'Mehr Knochen',
  horde_moreMonsterParts: 'Mehr Monsterteile',
  horde_moreSouls: 'Mehr Seelen',
  horde_moreMastery: 'Mehr Meisterung',
  horde_ancientPower: 'Antike Kraft',
  horde_ancientFortitude: 'Antike Resistenz',
  horde_ancientWealth: 'Antiker Reichtum',
  horde_ancientSpirit: 'Antike Spiritualität',
  horde_ancientSharpsight: 'Antikes Adlerauge',
  horde_ancientHolding: 'Antiker Speicher',
  horde_ancientReaping: 'Antike Sense',
  horde_ancientRemembrance: 'Antikes Andenken',
  horde_ancientExpertise: 'Antike Expertise',
  horde_ancientMystery: 'Antikes Mysterium',

  // Horde books
  horde_bookTraining: 'Buch: Training',
  horde_bookLuckyStrike: 'Buch: Glückstreffer',
  horde_bookLooting: 'Buch: Plündern',
  horde_bookSurvivalGuide: 'Buch: Überlebens-Anleitung',
  horde_bookCarving: 'Buch: Schnitzen',
  horde_bookWhitePaint: 'Buch: Weiße Farbe',

  // Farm upgrades
  farm_seedBox: 'Saatkasten',
  farm_fertility: 'Fruchtbarkeit',
  farm_expansion: 'Expansion',
  farm_gardenGnome: 'Gartenzwerg',
  farm_overgrowth: 'Überwuchern',
  farm_learning: 'Lernen',
  farm_manure: 'Dünger',
  farm_groundSeeds: 'Zermahlene Saat',
  farm_roastedSeeds: 'Geröstete Saat',
  farm_hayBales: 'Heuballen',
  farm_smallCrate: 'Kleine Kiste',
  farm_sprinkler: 'Rasensprenger',
  farm_scarecrow: 'Vogelscheuche',
  farm_magnifyingGlass: 'Lupe',
  farm_bugPowder: 'Käferpulver',
  farm_shed: 'Schuppen',
  farm_lectern: 'Rednerpult',
  farm_perfume: 'Parfüm',
  farm_mediumCrate: 'Mittlere Kiste',
  farm_stompedSeeds: 'Zerstampfte Saat',
  farm_insectParadise: 'Insektenparadies',
  farm_goldenTools: 'Goldwerkzeug',
  farm_butterflyWings: 'Schmetterlingsflügel',
  farm_fertileGround: 'Fruchtbarer Boden',
  farm_pinwheel: 'Windrad',
  farm_mysticGround: 'Mystischer Boden',
  farm_fertilizerBag: 'Düngerbeutel',
  farm_bigCrate: 'Große Kiste',
  farm_artificialWebs: 'Künstliche Netze',
  farm_studyInsects: 'Insekten studieren',
  farm_beehive: 'Bienenstock',
  farm_flag: 'Flagge',

  // Farm premium upgrades
  farm_biggerVegetables: 'Größeres Gemüse',
  farm_biggerFruits: 'Größere Früchte',
  farm_biggerGrain: 'Größeres Getreide',
  farm_biggerFlowers: 'Größere Blumen',
  farm_moreExperience: 'Mehr Erfahrung',
  farm_premiumGardenGnome: 'Premium-Gartenzwerg',
  farm_premiumSprinkler: 'Premium-Rasensprenger',
  farm_premiumLectern: 'Premium-Rednerpult',
  farm_premiumPinwheel: 'Premium-Windrad',
  farm_premiumFlag: 'Premium-Flagge',

  // Farm books
  farm_bookSmallCrate: 'Buch: Kleine Kiste',
  farm_bookScarecrow: 'Buch: Vogelscheuche',
  farm_bookShed: 'Buch: Schuppen',
  farm_bookMediumCrate: 'Buch: Mittlere Kiste',
  farm_bookInsectParadise: 'Buch: Insektenparadies',
  farm_bookBigCrate: 'Buch: Große Kiste',

  // Gallery upgrades
  gallery_newStyle: 'Neuer Stil',
  gallery_recycling: 'Recycling',
  gallery_redPower: 'Rote Kraft',
  gallery_redConversion: 'Rote Umwandlung',
  gallery_filters: 'Filter',
  gallery_orangePower: 'Orange Kraft',
  gallery_redLuck: 'Rotes Glück',
  gallery_epiphany: 'Geistesblitz',
  gallery_trashCan: 'Mülleimer',
  gallery_orangeConversion: 'Orange Umwandlung',
  gallery_brush: 'Pinsel',
  gallery_auctionHouse: 'Auktionshaus',
  gallery_yellowPower: 'Gelbe Kraft',
  gallery_orangeLuck: 'Oranges Glück',
  gallery_yellowConversion: 'Gelbe Umwandlung',
  gallery_paintDrumStorage: 'Farbtrommellager',
  gallery_greenPower: 'Grüne Kraft',
  gallery_yellowLuck: 'Gelbes Glück',
  gallery_greenConversion: 'Grüne Umwandlung',
  gallery_redRage: 'Rote Wut',
  gallery_bluePower: 'Blaue Kraft',
  gallery_greenLuck: 'Grünes Glück',
  gallery_blueConversion: 'Blaue Umwandlung',
  gallery_purplePower: 'Lila Kraft',
  gallery_blueLuck: 'Blaues Glück',
  gallery_purpleConversion: 'Lila Umwandlung',

  // Gallery prestige upgrades
  gallery_artAcademy: 'Kunstakademie',
  gallery_redCrayon: 'Roter Malstift',
  gallery_rainbowJar: 'Regenbogenglas',
  gallery_trashContainer: 'Abfallcontainer',
  gallery_orangeCrayon: 'Oranger Malstift',
  gallery_headstart: 'Vorsprung',
  gallery_forklift: 'Gabelstapler',
  gallery_redCrate: 'Rote Kiste',
  gallery_yellowCrayon: 'Gelber Malstift',
  gallery_inspiringBooks: 'Inspirierende Bücher',
  gallery_expressDelivery: 'Expresslieferung',
  gallery_orangeCrate: 'Orange Kiste',
  gallery_greenCrayon: 'Grüne Malstift',
  gallery_sortingSystem: 'Sortiersystem',
  gallery_redTruck: 'Roter LKW',
  gallery_yellowCrate: 'Gelbe Kiste',
  gallery_blueCrayon: 'Blauer Malstift',
  gallery_orangeTruck: 'Oranger LKW',
  gallery_greenCrate: 'Grüne Kiste',
  gallery_purpleCrayon: 'Lila Malstift',

  // Gallery premium upgrades
  gallery_prettyColors: 'Schöne Farben',
  gallery_prettyConverter: 'Schöner Umwandler',
  gallery_prettyCash: 'Schönes Geld',
  gallery_prettyRed: 'Schönes Rot',
  gallery_prettyOrange: 'Schönes Orange',
  gallery_prettyYellow: 'Schönes Gelb',
  gallery_prettyGreen: 'Schönes Grün',
  gallery_prettyBlue: 'Schönes Blau',

  // Gallery books
  gallery_bookRedPower: 'Buch: Rote Kraft',
  gallery_bookOrangePower: 'Buch: Orange Kraft',
  gallery_bookYellowPower: 'Buch: Gelbe Kraft',
  gallery_bookGreenPower: 'Buch: Grüne Kraft',
  gallery_bookBluePower: 'Buch: Blaue Kraft',
  gallery_bookPurplePower: 'Buch: Lila Kraft',

  // Gem premium upgrades
  gem_topazBag: 'Topasbeutel',

  // School premium upgrades
  school_student: 'Schüler',

  // Treasure premium upgrades
  treasure_moreSlots: 'Mehr Plätze',
  treasure_moreFragments: 'Mehr Fragmente',

  // Event upgrades
  event_firefly: 'Glühwürmchen',
  event_glowshroom: 'Leuchtpilz',
  event_glowfish: 'Neonfisch',
  event_lantern: 'Laterne',
  event_campfire: 'Lagerfeuer',
  event_coral: 'Koralle',
  event_jellyfish: 'Qualle',
  event_nightbloom: 'Nachtblüher',
  event_neonlight: 'Neonbeleuchtung',
  event_sun: 'Sonne',
  event_moonglow: 'Mondschein',
  event_burningFly: 'Feuerwürmchen',
  event_moreSpores: 'Mehr Sporen',
  event_fins: 'Flossen',
  event_lamppost: 'Laternenpfahl',
  event_campfireStories: 'Lagerfeuer-Geschichten',
  event_sponge: 'Schwamm',
  event_longerTentacles: 'Längere Tentakel',
  event_nightTime: 'Abendstunde',
  event_city: 'Stadt',
  event_moreHelium: 'Mehr Helium',
  event_furiousFly: 'Wutwürmchen',
  event_mycelium: 'Myzel',
  event_gills: 'Kiemen',
  event_dimmer: 'Dimmer',
  event_marshmallows: 'Marshmallows',
  event_anemone: 'Anemone',
  event_toxin: 'Toxin',
  event_fullMoon: 'Vollmond',
  event_ads: 'Reklame',
  event_lessDistance: 'Weniger Distanz',
  event_fireflyEnlightened: 'Erleuchtetes Glühwürmchen',
  event_glowshroomEnlightened: 'Erleuchtete Leuchtpilz',
  event_glowfishEnlightened: 'Erleuchteter Neonfisch',
  event_lanternEnlightened: 'Erleuchtete Laterne',
  event_campfireEnlightened: 'Erleuchtetes Lagerfeuer',
  event_coralEnlightened: 'Erleuchtete Koralle',
  event_jellyfishEnlightened: 'Erleuchtete Qualle',
  event_nightbloomEnlightened: 'Erleuchteter Nachtblüher',
  event_neonlightEnlightened: 'Erleuchtete Neonbeleuchtung',
  event_sunEnlightened: 'Erleuchtete Sonne',

  event_colorfulSeedBag: 'Farbenfroher Saatbeutel',
  event_flowerPot: 'Blumentopf',
  event_daisyProtection: 'Gänseblümchenschutz',
  event_poppyProtection: 'Mohnschutz',
  event_poppyFertilizer: 'Mohndünger',
  event_irisProtection: 'Schwertlilienschutz',
  event_irisFertilizer: 'Schwertliliendünger',
  event_lilyProtection: 'Lilienschutz',
  event_lilyFertilizer: 'Liliendünger',
  event_orchidProtection: 'Orchideenschutz',
  event_orchidFertilizer: 'Orchideendünger',
  event_cornflowerProtection: 'Kornblumenschutz',
  event_cornflowerFertilizer: 'Kornblumendünger',
  event_greenhouse: 'Gewächshaus',
  event_hugeVase: 'Riesenvase',
  event_framedDaisy: 'Eingerahmtes Gänseblümchen',
  event_framedPoppy: 'Eingerahmter Mohn',
  event_framedIris: 'Eingerahmte Schwertlilie',
  event_framedLily: 'Eingerahmte Lilie',
  event_framedOrchid: 'Eingerahmte Orchidee',
  event_framedCornflower: 'Eingerahmte Kornblume',

  event_juicyBait: 'Saftiger Köder',
  event_incubator: 'Inkubator',
  event_fishWhistle: 'Fischflöte',
  event_pollution: 'Verschmutzung',
  event_goldenHook: 'Goldener Haken',

  event_extraBuildingSlot: 'Extra-Bauplatz',
  event_doubleTime: 'Doppel-Zeit',
  event_tropicalBlessing: 'Tropischer Segen',

  event_essenceCondenser: 'Essenz-Kondensator',
  event_luckyCharm: 'Glücksanhänger',
  event_biggerCauldron: 'Größerer Kessel',
  event_potionShelf: 'Trankregal',
  event_ritualChalk: 'Rituelle Kreide',
  event_stabilizer: 'Stabilisator',
  event_pedestals: 'Sockel',
  event_mystifier: 'Mystifikator',
  event_bundle: 'Bündel',
  event_bagOfCandy: 'Süßigkeitenbeutel',

  event_pineTrees: 'Tannen',
  event_woolHat: 'Wollmütze',
  event_mulledWine: 'Glühwein',
  event_cookies: 'Kekse',
  event_iceSculpture: 'Eisskulptur',
  event_attackBoost: 'Angriffsboost',
  event_healthBoost: 'Lebensboost',
  event_lootBoost: 'Beuteboost',
};
