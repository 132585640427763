export default {
    price: 1000,
    light: {
        primary: '#787878',
        secondary: '#424242',
        accent: '#C0C0C0',
    },
    dark: {
        primary: '#787878',
        secondary: '#424242',
        accent: '#C0C0C0',
    }
}
