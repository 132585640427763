export default {
    // Pond fish
    bronzefish: {
        iconSize: 1.1,
        color: 'pale-red',
        location: 'pond',
        size: 4.5,
        weight: 1.25,
    },
    snail: {
        icon: 'mdi-snail',
        iconSize: 0.6,
        color: 'green',
        location: 'pond',
        size: 2.7,
        weight: 3.7,
        rain: true,
    },
    cablebiter: {
        iconSize: 0.75,
        color: 'dark-grey',
        location: 'pond',
        size: 6,
        minPower: 5,
        weight: 2.8,
        sun: true,
    },
    blueshimmer: {
        iconSize: 1,
        color: 'pale-blue',
        location: 'pond',
        size: 7.8,
        minPower: 8,
        weight: 2.2,
        snow: true,
    },
    introvero: {
        iconSize: 0.6,
        color: 'skyblue',
        location: 'pond',
        size: 6.2,
        minPower: 14,
        weight: 0.1,
        sun: false,
        rain: true,
    },
    zapling: {
        iconSize: 0.7,
        color: 'yellow',
        location: 'pond',
        size: 4.1,
        minPower: 17,
        weight: 1.6,
        thunder: true,
    },
    starcone: {
        icon: 'mdi-snail',
        iconSize: 0.7,
        color: 'brown',
        location: 'pond',
        size: 2.4,
        minPower: 26,
        weight: 3.1,
        snow: true,
    },
    phelaria: {
        iconSize: 1.7,
        color: 'orange',
        location: 'pond',
        size: 6.4,
        minPower: 38,
        weight: 0.55,
        rain: false,
        snow: false,
        thunder: false,
    },
    coldgil: {
        iconSize: 0.9,
        color: 'teal',
        location: 'pond',
        size: 3.7,
        minPower: 50,
        weight: 0.8,
        rain: true,
    },

    // Lake fish
    silverbrass: {
        iconSize: 1.15,
        color: 'light-grey',
        location: 'lake',
        size: 7.1,
        weight: 1.35,
    },
    circlejelly: {
        icon: 'mdi-jellyfish',
        iconSize: 0.85,
        color: 'light-blue',
        location: 'lake',
        size: 5.5,
        minPower: 42,
        weight: 1.1,
        sun: true,
    },
    woodcrawler: {
        icon: 'mdi-bug',
        iconSize: 0.7,
        color: 'wooden',
        location: 'lake',
        size: 2.8,
        minPower: 55,
        weight: 2.3,
        sun: false,
    },
    longdano: {
        iconSize: 1.1,
        color: 'light-green',
        location: 'lake',
        size: 4,
        minPower: 61,
        weight: 1.9,
        wind: true,
    },
    legabara: {
        iconSize: 1.8,
        color: 'dark-blue',
        location: 'lake',
        size: 9.2,
        minPower: 78,
        weight: 0.05,
    },
    biggiesnail: {
        icon: 'mdi-snail',
        iconSize: 1.25,
        color: 'pale-purple',
        location: 'lake',
        size: 4.7,
        minPower: 90,
        weight: 0.9,
        rain: true,
    },
    sunshine: {
        iconSize: 1,
        color: 'orange',
        location: 'lake',
        size: 5.1,
        minPower: 115,
        weight: 0.2,
        sun: true,
        thunder: true,
    },

    // River fish
    platiglob: {
        iconSize: 1.2,
        color: 'blue-grey',
        location: 'river',
        size: 10.3,
        weight: 1.45,
    },
    stormdazer: {
        iconSize: 0.9,
        color: 'yellow',
        location: 'river',
        size: 9.3,
        minPower: 104,
        weight: 2.2,
        rain: true,
        thunder: true,
    },
    riverTurtle: {
        icon: 'mdi-turtle',
        iconSize: 1.15,
        color: 'pale-green',
        location: 'river',
        size: 12,
        minPower: 128,
        weight: 1.1,
        snow: false,
        thunder: false,
        wind: false,
    },
    streamsnail: {
        icon: 'mdi-snail',
        iconSize: 0.8,
        color: 'lime',
        location: 'river',
        size: 7.2,
        minPower: 145,
        weight: 1.1,
        wind: true,
    },
    ralmon: {
        iconSize: 1.5,
        color: 'red',
        location: 'river',
        size: 11.6,
        minPower: 170,
        weight: 2.15,
    },
    wonelle: {
        iconSize: 1.3,
        color: 'light-grey',
        location: 'river',
        size: 10.8,
        minPower: 202,
        weight: 1.4,
        snow: true,
    },
    grillgil: {
        iconSize: 0.95,
        color: 'darker-grey',
        location: 'river',
        size: 10,
        minPower: 217,
        weight: 1.6,
        thunder: true,
    },
    sleepysoo: {
        iconSize: 0.7,
        color: 'cherry',
        location: 'river',
        size: 8.5,
        minPower: 244,
        weight: 0.8,
        rain: true,
    },
    oozior: {
        iconSize: 1.8,
        color: 'purple',
        location: 'river',
        size: 14,
        minPower: 290,
        weight: 0.1,
    },

    // Ocean fish
    paleblob: {
        iconSize: 1.25,
        color: 'lighter-grey',
        location: 'ocean',
        size: 14.6,
        weight: 1.55,
    },

    // Mountain fish
    crystakin: {
        iconSize: 1.3,
        color: 'light-blue',
        location: 'mountain',
        size: 18.1,
        weight: 1.65,
    },

    // Cave fish
    shadowbiter: {
        iconSize: 1.35,
        color: 'darker-grey',
        location: 'cave',
        size: 24.5,
        weight: 1.75,
    },

    // mdi-bug mdi-dolphin mdi-jellyfish mdi-shark mdi-snail mdi-turtle
}
