export default {
  torch: '火炬',
  purpleHeart: '紫心勋章',
  rottenLeaf: '腐烂的叶子',
  stonepiercer: '雪国列车',
  consolationPrize: '安慰奖',
  prettyLamp: '漂亮的灯',
  chessboard: '棋盘',
  iridiscentFlower: '彩虹色的花朵',
  excavator: '挖掘机',
  redCard: '红牌',
  briefcase: '公文包',
  strangePlant: '奇怪的植物',
  beneficialVirus: '有益的病毒',
  friendlyBat: '友好的蝙蝠',
  hammer: '锤子',
  aluminiumBrick: '铝砖',
  copperBrick: '铜砖',
  aluminiumHeap: '铝堆',
  bomb: '炸弹',
  copperHeap: '铜堆',
  radar: '雷达',
  press: '报刊',
  cupboard: '橱柜',
  catalyst: '催化剂',
  jumprope: '跳绳',
  bronzePickaxe: '青铜镐',
  oreShelf: '矿石架',
  coalBrick: '煤砖',
  washingMachine: '洗衣机',
  magnet: '磁铁',
  copperPickaxe: '铜镐',
  tinBucket: '锡桶',
  honeyPot: '一罐蜂蜜',
  openedGift: '打开的礼物',
  mudBrick: '泥砖',
  sapling: '树苗',
  keychain: '钥匙链',
  treasureChest: '宝箱',
  screwdriver: '螺丝刀',
  rose: '玫瑰',
  goldenKey: '金钥匙',
  supervisor: '导师',
  globe: '地球仪',
  forgottenShield: '被遗忘的盾牌',
  burningSkull: '燃烧的头骨',
  dumbbell: '哑铃',
  energyDrink: '能量饮料',
  luckyDice: '幸运骰子',
  newBackpack: '新背包',
  crackedSafe: '破裂的保险箱',
  ultimateGuide: '终极指南',
  bandage: '绷带',
  goldenCarrot: '金胡萝卜',
  goldenApple: '金苹果',
  popcorn: '爆米花',
  roseQuartz: '蔷薇石英',
  goldenSeed: '金种子',
  trellis: '格子',
  brickWall: '砖墙',
  printer: '打印机',
  lightbulb: '灯泡',
  oldTV: '旧电视',
  worryingMail: '令人担忧的邮件',
  redBalloon: '红色气球',
  sackOfGold: '一袋金子',
  shredder: '碎纸机',
  redprint: '红色印花',
  orangeBalloon: '橙色气球',
  creditCard: '信用卡',
  simpleCalculator: '简单计算器',
  orangeprint: '橙色印花',
  yellowBalloon: '黄色气球',
  tinfoilHat: '锡纸帽',
  cupOfWater: '一杯水',
  combatStrategy: '作战策略',
  hundredDollarBill: '100 美元钞票',
  hotAirBalloon: '热气球',
  largeClover: '大三叶草',
  eightBall: '8球',
  youngPig: '年轻的猪',
  silverHorseshoe: '银色马蹄铁',
  bronzeTools: '青铜工具',
  minersHat: '矿工帽',
  dictionary: '字典',
  expertTools: '专业工具',
  bloodBag: '血袋',
  geode: '晶洞',
  birthdayCake: '生日蛋糕',
  colorfulFlower: '七彩花',
  heatingBulb: '加热灯泡',
  trashCan: '垃圾箱',
  suitcase: '手提箱',
  tropicalTent: '热带帐篷',
  fruitBasket: '水果篮',
  massiveGrain: '块状颗粒',
  enchantedBottle: '魔法瓶',
  moneyGift: '金钱礼物',
  frozenCarrot: '冷冻胡萝卜',
};
